import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { MessageModalComponent } from '@shared/components';
import {
  AccountsService,
  AccountTypes,
  CommsService,
  LoadingService,
  TeamsService,
  UserdataService,
  UserService
} from '@services';
import { ScoresService } from './../scores/scores.service';

import { TranslateService } from '@ngx-translate/core';
import { each, find, flatMapDeep, get, identity, isEmpty, keys, map, orderBy, pickBy, values } from 'lodash';

export enum WidgetType {
  WorkerPower,
  ObservationImpact,
  ReceivedThumbsUp
}

export interface ILeaderboardDataRow {
  index: number;
  id: number;
  name: string;
  fullName: string;
  value: number | string;
  isTeam?: boolean;
  avatarUrl?: string;
  inactive?: boolean;
}

export interface ILeaderboardTableParams {
  target: HTMLElement;
  columns: any[];
  data: ILeaderboardDataRow[];
  height?: number | string;
  deferRender?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {

  public selectedFilter: any = {};

  private readonly defaultDataAmount: number = 10;

  constructor(
    private userService: UserService,
    private userDataService: UserdataService,
    private translate: TranslateService,
    private modalController: ModalController,
    private scoresService: ScoresService,
    private teamsService: TeamsService,
    private accountsService: AccountsService,
    private commsService: CommsService,
    private loadingService: LoadingService
  ) {
  }

  public getListFieldsByWidgetType(type: WidgetType, isTeam: boolean = false): string[] {
    let fields: string[] = [];

    const teamFieldName: string = this.translate.instant('DASHPAGES.TEAM');
    const userFieldName: string = this.translate.instant('DASHPAGES.USER');
    const adjustedScoreFieldName: string = this.translate.instant('DASHPAGES.ADJUSTED_SCORE');
    const avgImpactFieldName: string = this.translate.instant('DASHPAGES.AVG_IMPACT');
    const scoreFieldName: string = this.translate.instant('DASHPAGES.SCORE');
    const adjustedFieldName: string = this.translate.instant('DASHPAGES.ADJUSTED');
    const receivedFieldName: string = this.translate.instant('DASHPAGES.RECEIVED');

    if (type === WidgetType.WorkerPower) {
      fields = isTeam ? [teamFieldName, adjustedScoreFieldName] : [userFieldName, scoreFieldName];
    } else if (type === WidgetType.ObservationImpact) {
      fields = isTeam ? [teamFieldName, avgImpactFieldName] : [userFieldName, avgImpactFieldName];
    } else {
      fields = isTeam ? [teamFieldName, adjustedFieldName] : [userFieldName, receivedFieldName];
    }

    return fields;
  }

  public getData(widgetType: WidgetType, isTeam: boolean = false, fullList: boolean = false, count?: number): ILeaderboardDataRow[] {
    const dataKey: 'teamScores' | 'userScores' = isTeam ? 'teamScores' : 'userScores';
    const data: any = pickBy(get(this.scoresService.scores.data, dataKey, {}), (value: any, key: string) => +key);
    return this.prepareData(data, widgetType, isTeam, fullList, count);
  }

  public initTable(params: ILeaderboardTableParams): any {
    let dataTableOptions: any = {
      ordering: false,
      language: {
        emptyTable: this.translate.instant('SHARED.emptyTable'),
        sZeroRecords: this.translate.instant('SHARED.sZeroRecords')
      },
      scrollY: params.height || 197.5,
      scrollX: true,
      paging: false,
      searching: false,
      info: false,
      retrieve: true,
      stateSave: false,
      columns: params.columns,
      data: params.data,
      columnDefs: [
        {targets: 0, width: '10px'},
        {targets: 1, width: '65%'},
      ],
      dom: 'frtip'
    };

    if (params.deferRender) {
      const pagePadding = 40;
      const tableHeight: number = $(window).height() - $(params.target).offset().top - pagePadding;

      dataTableOptions = Object.assign(dataTableOptions, {
        scrollY: tableHeight,
        paging: true,
        deferRender: true,
        scroller: {
          displayBuffer: 5,
          rowHeight: 60
        }
      });
    }

    const table: any = $(params.target).DataTable(dataTableOptions);
    $(table.table().header()).addClass('table-header sorting');

    $(params.target).off('click').on('click', 'tr', (event: Event) => {
      this.onRowClick(table, event);
    });

    return table;
  }

  public updateTable(table: any, items: ILeaderboardDataRow[], fields: string[]): void {
    each(fields, (field: string, index: number) => {
      $(table.column(index + 1).header()).text(field);
    });
    table.clear().rows.add(items).draw(false).columns.adjust();
  }

  public prepareTableFieldTitles(columns: any[], titles: string[]): void {
    each(titles, (title: string, index: number) => {
      columns[index + 1].title = title;
    });
  }

  public showMessageModal(id: number, isTeam: boolean): void {
    this.modalController.create(<any>{
      component: MessageModalComponent,
      cssClass: 'fullscreen-modal',
      componentProps: {isTeam, id}
    }).then((element: HTMLIonModalElement) => {
      element.present();
    });
  }

  public addFilter(filter: string): Promise<any> {
    const params: any = {
      cmd: 'addFilter',
      type: 'leaderboard',
      selectors: JSON.stringify(filter)
    };

    return this.commsService.sendMessage(params, false, false).then((response: any) => {
      this.loadingService.disable();

      if (response && response.reqStatus === 'OK') {
        return response.result;
      }
      return null;
    });
  }

  public getFilters(): Promise<any> {
    const params: any = {cmd: 'getFilters', types: [ 'leaderboard' ]};
    this.loadingService.enable(null, null);

    return this.commsService.sendMessage(params, false, false).then((response: any) => {
      this.loadingService.disable();

      if (response && response.reqStatus === 'OK') {
        return get(response, 'result.filters', []);
      }
      return null;
    }).catch(() => {
      this.loadingService.disable();
    });
  }

  private flatArrayByChildren(groupOptions: any[]) {
    const getMembers = (group)=>{
      if(!group.children || !group.children.length){
        return group;
      }
      return [group, flatMapDeep(group.children, getMembers)];
    }

    return flatMapDeep(groupOptions, getMembers);
  }

  public getPreparedFilterData(filterComps?: any): any {
    const userTypes: string[] = get(this.selectedFilter, 'accountType', []) || [];
    const shifts: string[] = get(this.selectedFilter, 'shift', []) || [];
    const permissionLevels: string[] = get(this.selectedFilter, 'permissionLevel', []) || [];

    let groups: number[] = get(this.selectedFilter, 'groups', []) || [];
    let locations: number[] = get(this.selectedFilter, 'locations', []) || [];

    if (isEmpty(locations)) {
      if (filterComps) {
        const locationsOptions = (find(filterComps, {id: 'locations'}) as any)?.dropDownOptions;
        locations = map(this.flatArrayByChildren(locationsOptions), 'id');
      } else {
        locations = this.userDataService.locations;
      }
    }

    if (isEmpty(groups)) {
      if (filterComps) {
        let groupOptions = (find(filterComps, {id: 'groups'}) as any)?.dropDownOptions;
        groups = map(this.flatArrayByChildren(groupOptions), 'id');
      } else {
        groups = map(this.teamsService.getTeamList(this.userDataService.locations), 'groupID');
      }
    }

    const params: any = {
      userTypes: userTypes.length ? JSON.stringify(userTypes) : null,
      locations: locations.length ? JSON.stringify(locations) : null,
      shifts: shifts.length ? JSON.stringify(shifts) : null,
      groups: groups.length ? JSON.stringify(groups) : null,
      permissions: permissionLevels.length ? JSON.stringify(permissionLevels) : null,
      startTime: get(this.selectedFilter, 'startTime', null),
      endTime: get(this.selectedFilter, 'endTime', null),
      period: get(this.selectedFilter, 'period', null),
      timespan: get(this.selectedFilter, 'timespan', null)
    };

    return pickBy(params, identity);
  }

  public applySavedFilter(filter): any {
    if (filter) {
      delete filter.isTeam;

      const selectedFilter: any = {
        accountType: filter.userTypes ? JSON.parse(filter.userTypes) : null,
        locations: filter.locations ? JSON.parse(filter.locations) : null,
        shift: filter.shifts ? JSON.parse(filter.shifts) : null,
        groups: filter.groups ? JSON.parse(filter.groups) : null,
        permissionLevel: filter.permissions ? JSON.parse(filter.permissions) : null,
        startTime: get(filter, 'startTime', null),
        endTime: get(filter, 'endTime', null),
        period: get(filter, 'period', null),
        timespan: get(filter, 'timespan', null),
      };

      this.selectedFilter = pickBy(selectedFilter, identity);
    }
  }

  public calculateScores(): Promise<any> {
    return this.scoresService.calculateScores(this.getPreparedFilterData(), false, true);
  }

  public updateData(enableLoading?: boolean): Promise<any> {
    const params: any = this.getPreparedFilterData();
    return this.scoresService.getScores(params, enableLoading);
  }

  private prepareData(data: any, widgetType: WidgetType, isTeam: boolean = false, fullList: boolean = false, count?: number): ILeaderboardDataRow[] {
    const ids: number[] = map(keys(data), Number);
    const cardNames: string[] = map(ids, (id: number) => this.getCardName(id, isTeam));
    const fullNames: string[] = map(ids, (id: number) => this.getCardFullName(id, isTeam));

    let listCards: ILeaderboardDataRow[] = orderBy(map(values(data), (item: any, index: number) => {
      let value: number;
      const timespan: string = get(this.selectedFilter, 'timespan');
      const key: string = !timespan || timespan === 'all' ? 'all' : 'timespan';

      if (widgetType === WidgetType.WorkerPower) {
        const pointsKey: string = isTeam ? `points.${key}.total` : `points.${key}`;
        value = get(item, pointsKey, 0);
      } else if (widgetType === WidgetType.ObservationImpact) {
        const total: number = get(item, `impact.${key}.total`, 0) || 0;
        const count: number = get(item, `impact.${key}.count`, 1) || 1;
        value = total / count;
      } else if (widgetType === WidgetType.ReceivedThumbsUp) {
        const receivedKey: string = isTeam ? `received.${key}.total` : `received.${key}`;
        value = get(item, receivedKey, 0);
      }

      return {
        id: ids[index],
        name: cardNames[index],
        fullName: fullNames[index],
        value,
        avatarUrl: isTeam ? null : this.userService.accountAvatar(ids[index], null, false, true),
        isTeam,
        index: 0,
        inactive: isTeam ? false : get(this.accountsService.getAccount(+ids[index]), 'type') === AccountTypes.Observer
      };
    }), ['value'], ['desc']);

    listCards = map(listCards, (listCard: ILeaderboardDataRow, index: number) => {
      listCard.index = index + 1;
      return listCard;
    });

    return fullList ? listCards : listCards.slice(0, count || this.defaultDataAmount);
  }

  private getCardName(id: number, isTeam: boolean): string {
    return isTeam ? this.teamsService.teamNameByID(id) : this.accountsService.username(id);
  }

  private getCardFullName(id: number, isTeam: boolean): string {
    return isTeam ? this.teamsService.teamNameByID(id) : this.accountsService.fullUserName(id);
  }

  private onRowClick(table: any, event: Event): void {
    const row: ILeaderboardDataRow = table.row(event.currentTarget).data();

    if (row && !row.inactive) {
      this.showMessageModal(row.id, row.isTeam);
    }
  }

}
