<div class="card-user">
  <img [src]="avatarUrl">

  <div class="card-details">
    <div class="card-details-header">
      <span class="card-user-name">{{ name }}</span>
      <span *ngIf="time" class="card-date"> | {{ time }}</span>
      <span *ngFor="let text of headerPostText" class="card-date"> | <span [ngClass]="headerPostTextClass">{{ text | translate }}</span></span>
    </div>
    <div class="text" *ngIf="type === 'text'" [innerHTML]="text | markdown | async"></div>

    <audio *ngIf="type ==='audio'" class="note-audio" controls="" preload="metadata">
      <source [src]="text" type="audio/mp4">
    </audio>
  </div>
</div>
