import { Location } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';

import { MessagePopupComponent } from '@modules/observation/components/message-popup/message-popup.component';
import { WorkorderPopupComponent } from '@modules/observation/components/workorder-popup/workorder-popup.component';
import { PopupObservationService } from '@services/observationDetail/popup-observation.service';
import { ICopyConfig } from '@shared/modules/copy-link/components/copy-link/copy-link.component';
import { PopoverService } from '@services';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

export interface IHeaderData {
  copyLink?: ICopyConfig;
}

@Component({
  selector: 'app-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.scss'],
})
export class DetailHeaderComponent implements OnChanges {
  @Input() headerData: IHeaderData | any;
  public reportId: string = this.route.snapshot.queryParamMap.get('reportId');
  private prevState: string = this.route.snapshot.queryParamMap.get('prevState');
  private zoneEvent: any;
  private oid: any;
  protected updatePopoverConfig: any = {
    title: this.translate.instant('SITE_PAGES.NEW_ZONE_Update'),
    description: this.translate.instant('MGMT_DETAILS.Change_Zone_Text'),
    onSave: () => {
      this.popOps.zoneChange(this.zoneEvent.value, this.oid);
    },
    onCancel: () => {
      this.headerData = cloneDeep(this.headerData);
    }
  };
  public copyConfig: ICopyConfig = {};

  constructor(
    private popOps: PopupObservationService,
    private popover: PopoverController,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private popoverService: PopoverService,
  ) {}

  ngOnChanges() {
    this.defineCopyUrl();
  }

  public dropDownChanged(event, oid) {
    this.zoneEvent = event;
    this.oid = oid;
    this.popoverService.show(this.updatePopoverConfig);
  }

  public print() {
    print();
  }

  public messageUser(uObj) {
    const tempUobj = cloneDeep(uObj);
    tempUobj.name = uObj.data;
    this.popover.create(<any>{
      component: MessagePopupComponent,
      animated: false,
      componentProps: {
        userObject: tempUobj,
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public tableWidth(headerElement) {
    if (headerElement.name === 'SHARED.LOGGED') {
      return 'date-width';
    } else if (headerElement.name === 'OTable.TARGET') {
      return 'joined-names';
    } else {
      return headerElement.image ? 'sl-width' : 'normal-width';
    }

  }

  public woEdit(oid, wid) {
    this.popover.create(<any>{
      component: WorkorderPopupComponent,
      animated: false,
      componentProps: {
        observationID: oid,
        workOrder: wid
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public backToReportDetail(): void {
    this.location.back();
  }

  public backToReport(): void {
    const routeParams: any = {};
    if (+this.reportId) {
      const idKey: string = this.prevState === 'view' ? 'report' : 'message';
      routeParams[`${idKey}ID`] = this.reportId;
    }
    this.router.navigate([`pages/reporting/${this.prevState}`, routeParams], {replaceUrl: true});
    window.history.go(-2);
  }

  public openLink(data) {
    this.router.navigate([data.link], {queryParams: data.queryParams});
  }

  private defineCopyUrl() {
    if (this.headerData.uuid) {
      if (this.headerData.hideNumber) {
        this.copyConfig.url = this.popOps.shareExternal(this.headerData.uuid);
      } else {
        this.copyConfig.url = this.popOps.shareLink(this.headerData.uuid);
      }
    }
  }
}
