<div class="note-container">
  <div class="note-title">Submission</div>

  <app-note-card *ngIf="submissionNote; else noData"
                        [avatarUrl]="submissionNote.avatarUrl"
                        [name]="submissionNote.name"
                        [text]="submissionNote.text"
                        [time]="submissionNote.time"></app-note-card>

  <ng-template #noData>{{ 'SHARED.No_Data' | translate }}</ng-template>
</div>
