import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { AlertController } from '@ionic/angular';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';

import { Events } from '@services/events/events.service';
import { HistoryBlockingLocationStrategyService } from '@services/historyBlockingLocationStrategy/history-blocking-location-strategy.service';

import { get, includes } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ChangeDetectionDeactivator implements CanDeactivate<null> {

  constructor(
    private events: Events,
    private alertController: AlertController,
    private translateService: TranslateService,
    private location: Location,
    private locationStrategy: HistoryBlockingLocationStrategyService
  ) {
  }

  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Promise<boolean> | boolean {
    const isFormChanged: boolean = get(component, 'isFormChanged') && component.isFormChanged();
    const skipChangeDetection: boolean = includes(nextState.url, 'skipChangeDetection=true');

    if (isFormChanged && !skipChangeDetection) {
      return this.tryDeactivate(currentRoute, currentState, nextState);
    }

    return true;
  }

  public async showAlert(nextNavigationHandler: () => void, onDidDismissHandler?: () => void) {
    const params = {
      header: this.translateService.instant('SHARED.Are_You_Sure'),
      message: this.translateService.instant('SHARED.Change_Detection_Message'),
      cssClass: 'custom-alert',
      buttons: [
        {text: this.translateService.instant('SHARED.Cancel')},
        {
          text: this.translateService.instant('SHARED.Yes_Sure'),
          handler: () => nextNavigationHandler()
        }
      ]
    };

    const alert: HTMLIonAlertElement = await this.alertController.create(params);
    alert.present();

    alert.onDidDismiss().then(() => {
      onDidDismissHandler && onDidDismissHandler();
    });
  }

  private tryDeactivate(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Promise<boolean> {
    let canDeactivate = false;
    const isHeaderHidden: boolean = get(currentRoute, 'data.headerConfig.isHidden');
    this.updateHeaderParams(currentState);

    return new Promise(async (resolve) => {
      const nextNavigationHandler = () => {
        canDeactivate = true;
        resolve(canDeactivate);
        this.updateHeaderParams(nextState);
      };
      const onDidDismissHandler = () => {
        if (!canDeactivate && isHeaderHidden) {
          this.stopNextPopstatePropagation();
        }
      };

      await this.showAlert(nextNavigationHandler, onDidDismissHandler);
    });
  }

  private updateHeaderParams(routerStateSnapshot: RouterStateSnapshot) {
    this.events.publish('ccs:updateHeaderParams', routerStateSnapshot);
  }

  private stopNextPopstatePropagation(): void {
    this.locationStrategy.stopNextPopstatePropagation();
    this.location.forward();
  }
}
