import { Injectable } from '@angular/core';

import { IObjectStringKeyMap } from '@shared/models';
import { SHARED_LINK } from '@modules/shared-link/shared-link.module';
import {
  AccountsService,
  CertificationsService,
  Feature,
  FilterElementService,
  FoldersDataService,
  FormBuilderService,
  FormConfig,
  FormField,
  Module,
  ObjectLifecycle,
  PermissionsService,
  RolesService,
  ShiftService,
  SubscriberService,
  UserdataService,
  UserService,
  UtilsService
} from '@services';
import { FolderFactoryService } from '@modules/management/modules/folders/services/folder-factory.service';

import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, each, filter, find, flatten, get, has, isArray, isEmpty, map, set } from 'lodash';
import { PropertyPickerComponent } from '@modules/management/modules/property-picker/components';
import { PropertyPickerConfig, SelectedProperty } from '@modules/management/modules/property-picker/model/property-picker.interfaces';
import { PropertySubject } from '@services/property/property-model.interfaces';
import { IFolder } from '@modules/management/modules/folders/model/folders.interfaces';


@Injectable({
  providedIn: 'root'
})
export class ContentFolderService extends FolderFactoryService {
  private accounts = this.accountsService.getUserlist(this.userdataService.locations);
  private locations = this.userService.getUserLocations(this.userdataService.locations, false);

  constructor(
    private translate: TranslateService,
    private accountsService: AccountsService,
    private formBuilderService: FormBuilderService,
    private roleService: RolesService,
    private permissionService: PermissionsService,
    private userdataService: UserdataService,
    private certificationsService: CertificationsService,
    private subscriber: SubscriberService,
    private userService: UserService,
    private filterService: FilterElementService,
    private shifts: ShiftService,
    private utils: UtilsService,
    private foldersDataService: FoldersDataService
  ) {
    super();
  }

  public getCopyFolderLink(folderID: number): string {
    return `${SHARED_LINK}/content/folder/${folderID}`;
  }

  public getCopyObjectLink(objectID: number, rowID?: number) {
    if (rowID) {
      return `${SHARED_LINK}/content/${objectID}/${rowID}`;
    } else {
      return `${SHARED_LINK}/content/${objectID}`;
    }
  }

  public onFinishRenderAddFolderForm(formConfig: FormConfig) {
    this.onAccessLocationChange(formConfig);
  }

  public getFields(folderData?: any): FormField[] {
    const disabled = !!folderData?.parentAttributes?.settings_inherited;
    const docReviewInherited = !!folderData?.parentAttributes?.document_review;
    const fields: FormField[] = [
      {
        labelClass: 'body-copy-bold',
        title: `${this.translate.instant('CONTENT.Folder_Only_Editable_By_Owner')}: (${this.accountsService.fullUserName(+folderData?.creator || +this.userdataService.userID)})`,
        tooltip: 'CONTENT.Folder_Editable_Description',
        name: 'owner_only',
        type: 'flipswitch',
        onText: this.translate.instant('SHARED.On'),
        offText: this.translate.instant('SHARED.Off'),
        value: 1,
      },
      {
        title: this.translate.instant('MGMT_DETAILS.Go_Tos'),
        type: 'selectmenu',
        name: 'users',
        multiple: true,
        valueProperty: 'userID',
        placeholder: '',
        disabled,
        options: this.accounts,
        test(ref) {
          if (ref?.disabledAt || !ref?.active) {
            return false;
          } else {
            if (ref.hasOwnProperty('type') && (ref.type === 'dedicated' || ref.type === 'shared')) {
              return true;
            } else {
              return false;
            }
          }
        },
        func: (ref) => this.accountsService.fullUserName(ref.userID)
      },
      ...this.getLimitContentAccessFields()
    ];

    if (this.subscriber.usesFeature(Feature.CONTENT_REVIEW)) {
      // when content review is enabled, add attributes for default
      // reviewers, number of required approvals, deadline, and re-review interval
      fields.push(...this.getDocumentReviewFields(disabled, docReviewInherited, false));
    }

    if (this.subscriber.usesModule(Module.PROPERTIES)) {
      fields.push(
      {
        type: 'customElement',
        name: 'selectedProperties',
        component: PropertyPickerComponent,
        module: Module.PROPERTIES,
        containerClass: 'review',
        inputs: {
          pickerConfig: <PropertyPickerConfig>{
            type: PropertySubject.Content
          },
          parentIds: this.getParentIds(folderData)
        }
      });
    }

    // If there are parents to this folder and there is inheritance, then lock out changes in this form

    return fields;
  }

  public onAccessLocationChange(formConfig: FormConfig): void {
    const locationField = find(formConfig.fields, {name: 'access_locations'});
    let locations = filter(map(flatten([locationField.getValue()]), Number));

    if (isEmpty(locations)) {
      locations = map(this.locations, 'locationID');
    }

    this.updateAccessShiftsBy(locations, formConfig);
    this.updateAccessTeamsBy(locations, formConfig);
  }

  private updateAccessTeamsBy(locationsIds: number[], formConfig: FormConfig) {
    const fieldName = 'access_teams';
    const fieldId = `#${formConfig.prefix}${fieldName}`;
    const fieldConfig = find(formConfig.fields, {name: fieldName});
    const selection: string[] | string = <string[] | string>fieldConfig.getValue();
    const fieldData: IObjectStringKeyMap<any> = {};

    if (fieldConfig.multiple) {
      fieldData[fieldName] = isArray(selection) ? map(selection, Number) : [+selection];
    } else {
      fieldData[fieldName] = selection;
    }

    fieldConfig.options = this.filterService.buildTeamMenu(locationsIds, false).dropDownOptions;
    this.formBuilderService.replaceSelectOptionsWith(fieldId, formConfig, fieldConfig, fieldData);
  }

  private updateAccessShiftsBy(locationsIds: number[], formConfig: FormConfig) {
    const fieldName = 'access_shifts';
    const fieldId = `#${formConfig.prefix}${fieldName}`;
    const fieldConfig = find(formConfig.fields, {name: fieldName});
    const selectedIds = fieldConfig.getValue();

    const locations = map(locationsIds, (locationId) => this.userService.getLocation(locationId));
    fieldConfig.options = this.shifts.getSelectMenuObjectsByLocations(locations);
    this.formBuilderService.replaceSelectOptionsWith(fieldId, formConfig, fieldConfig, {[fieldName]: selectedIds});
  }

  public getDocumentReviewFields(disabled = false, docReviewInherited = false, isChild = false, includeControl = true): FormField[] {
    if (!this.subscriber.usesFeature(Feature.CONTENT_REVIEW)) {
      return [];
    }

    const inheritedReviewControl: FormField = {
      containerClass: 'review',
      title: 'CONTENT.Folder_Settings_Inherited',
      tooltip: 'CONTENT.Folder_Inherited_Description',
      name: 'settings_inherited',
      disabled,
      type: 'flipswitch',
      onText: this.translate.instant('SHARED.On'),
      offText: this.translate.instant('SHARED.Off'),
      value: 1,
    };
    const docReviewDefault = docReviewInherited ? 1: 0;
    const reviewControl: FormField = {
        labelClass: 'body-copy-bold',
        title: 'CONTENT.Document_Review',
        tooltip: 'CONTENT.Flipswitch_Description',
        name: 'document_review',
        type: 'flipswitch',
        onText: this.translate.instant('SHARED.On'),
        offText: this.translate.instant('SHARED.Off'),
        disabled: docReviewInherited,
        default: docReviewDefault,
        value: 1,
        hideFieldsOnValue: {
          select: [{
            alwaysHide: true,
            hideClasses: ['review', 'basic'],
            value: 0,
            // valueFunction: (val) => {
            //   return val.length === 0;
            // }
            showClassesFunc: (value) => {
              const showClasses = [];
              if (value || docReviewDefault) {
                showClasses.push(ObjectLifecycle.Review);
              } else {
                showClasses.push(ObjectLifecycle.Basic);
              }
              return showClasses;
            }
          }]
        }
      };

    return filter<FormField>([
      includeControl ? reviewControl : null,
      isChild ? null : inheritedReviewControl,
      {
        title: 'CONTENT.Document_Review',
        type: 'divider',
        name: 'NONE',
        containerClass: 'review'
      },
      {
        title: 'CONTENT.Automation',
        type: 'subtitle',
        containerClass: 'review',
        name: 'NONE'
      },
      {
        title: this.translate.instant('CONTENT.AutoReject'),
        tooltip: 'CONTENT.AutoReject_Tooltip',
        containerClass: 'review',
        name: 'auto_reject',
        disabled,
        type: 'flipswitch',
        onText: this.translate.instant('SHARED.On'),
        offText: this.translate.instant('SHARED.Off'),
        value: 1,
        default: 0
      },
      {
        title: 'CONTENT.AutoApprove',
        tooltip: 'CONTENT.AutoApprove_Tooltip',
        containerClass: 'review',
        name: 'auto_approve',
        disabled,
        type: 'flipswitch',
        onText: this.translate.instant('SHARED.On'),
        offText: this.translate.instant('SHARED.Off'),
        value: 1,
        default: 0,
        hideFieldsOnValue: {
          select: [{
            alwaysHide: true,
            hideClasses: ['approve'],
            value: 0,
            // valueFunction: (val) => {
            //   return val.length === 0;
            // }
            showClassesFunc: (value, formConfig) => {
              const reviewField: FormField = find(formConfig.fields, {name: 'document_review'});
              let reviewEnabled = reviewField?.getValue();

              const showClasses = [];
              if ((docReviewInherited || reviewEnabled)  && !value ) {
                showClasses.push('approve');
              }
              return showClasses;
            }
          }]
        }
      },
      {
        title: this.translate.instant('CONTENT.AutoPublish'),
        tooltip: 'CONTENT.AutoPublish_Tooltip',
        containerClass: 'review',
        name: 'auto_publish',
        disabled,
        type: 'flipswitch',
        onText: this.translate.instant('SHARED.On'),
        offText: this.translate.instant('SHARED.Off'),
        value: 1,
        default: 0,
        hideFieldsOnValue: {
          select: [{
            alwaysHide: true,
            hideClasses: ['publish'],
            value: 0,
            // valueFunction: (val) => {
            //   return val.length === 0;
            // }
            showClassesFunc: (value, formConfig) => {
              const reviewField: FormField = find(formConfig.fields, {name: 'document_review'});
              let reviewEnabled = reviewField?.getValue();

              const showClasses = [];
              if ((docReviewInherited || reviewEnabled) && !value ) {
                showClasses.push('publish');
              }
              return showClasses;
            }
          }]
        }
      },
      {
        title: 'CONTENT.Document_Reviewers',
        type: 'subtitle',
        containerClass: 'review',
        name: 'NONE'
      },
      {
        title: 'CONTENT.Number_Required',
        type: 'number',
        containerClass: 'review',
        name: 'reviewer_count',
        disabled,
        required: true,
        min: 0,
        default: 0
      },
      {
        title: this.translate.instant('CONTENT.Review_Time'),
        type: 'timepicker',
        name: 'review_duration',
        containerClass: 'review',
        disabled,
        required: false,
        searchable: false,
        originalOrder: true,
        min: 0,
        options: [
          {
            id: 'minutes',
            description: this.translate.instant('SHARED.Minutes'),
          },
          {
            id: 'hours',
            description: this.translate.instant('SHARED.Hours'),
          },
          {
            id: 'days',
            description: this.translate.instant('SHARED.Days'),
          },
          {
            id: 'weeks',
            description: this.translate.instant('SHARED.Weeks'),
          },
          {
            id: 'months',
            description: this.translate.instant('SHARED.Months'),
          },
          {
            id: 'years',
            description: this.translate.instant('SHARED.Years'),
          }
        ]
      },
      {
        containerClass: 'review type-selector',
        title: 'CONTENT.Reviewer_Prompt',
        type: 'subtitle',
        name: 'NONE'
      },
      {
        title: this.translate.instant('SHARED.Specific_Users'),
        required: false,
        disabled,
        containerClass: 'review',
        name: 'reviewers',
        type: 'selectmenu',
        multiple: true,
        placeholder: this.translate.instant('SHARED.None'),
        valueProperty: 'userID',
        options: this.accounts,
        test: (ref) => {
          if (ref.disabledAt !== 0 || !ref.active) {
            return false;
          } else {
            return true;
          }
        },
        func: (ref) => this.userService.getFullname(ref.userID)
      },
      {
        containerClass: 'review',
        title: this.translate.instant('SHARED.TEAMS'),
        name: 'review_teams',
        disabled,
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Teams'),
        multiple: true,
        originalOrder: true,
        options: this.filterService.buildTeamMenu([], false).dropDownOptions
      },
      {
        containerClass: 'review',
        name: 'review_roles',
        title: this.translate.instant('LAYOUT.Roles'),
        placeholder: this.translate.instant('SHARED.All_Roles'),
        type: 'selectmenu',
        disabled,
        multiple: true,
        valueProperty: 'roleID',
        options: this.roleService.roles,
        test: (ref) => !get(ref, 'disabledAt'),
        func: (role: any) => this.roleService.roleName(role)
      },
      {
        containerClass: 'review',
        name: 'review_permissions',
        title: this.translate.instant('MGMT_DETAILS.Permissions'),
        placeholder: this.translate.instant('SHARED.All_Permissions'),
        type: 'selectmenu',
        disabled,
        multiple: true,
        options: cloneDeep(this.permissionService.permissions),
        test: (ref) => {
          ref.description = this.translate.instant(ref.description);
          if (ref.id === 'corvex') {
            return get(this.userdataService.Permissions, 'corvex');
          } else if (ref.id === 'sadmin') {
            return get(this.userdataService.Permissions, 'sadmin') || get(this.userdataService.Permissions, 'corvex');
          } else {
            return true;
          }
        }
      },
      {
        containerClass: 'review',
        title: this.translate.instant('SHARED.Certifications'),
        disabled,
        name: 'review_certifications',
        placeholder: this.translate.instant('SHARED.All_Certifications'),
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'certificationID',
        options: this.certificationsService.certifications,
        func: (certification) => this.certificationsService.certificationName(certification),
        test: (certification) => !get(certification, 'disabledAt')
      },
      {
        containerClass: 'review approve type-selector',
        title: 'CONTENT.Approver_Prompt',
        type: 'subtitle',
        name: 'NONE'
      },
      {
        title: this.translate.instant('SHARED.Specific_Users'),
        required: false,
        disabled,
        containerClass: 'review approve',
        name: 'approvers',
        type: 'selectmenu',
        multiple: true,
        placeholder: this.translate.instant('SHARED.None'),
        valueProperty: 'userID',
        options: this.accounts,
        test: (ref) => {
          if (ref.disabledAt !== 0 || !ref.active) {
            return false;
          } else {
            return true;
          }
        },
        func: (ref) => this.userService.getFullname(ref.userID)
      },
      {
        containerClass: 'review approve',
        title: this.translate.instant('SHARED.TEAMS'),
        name: 'approver_teams',
        disabled,
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Teams'),
        multiple: true,
        originalOrder: true,
        options: this.filterService.buildTeamMenu([], false).dropDownOptions
      },
      {
        containerClass: 'review approve',
        name: 'approver_roles',
        title: this.translate.instant('LAYOUT.Roles'),
        placeholder: this.translate.instant('SHARED.All_Roles'),
        type: 'selectmenu',
        disabled,
        multiple: true,
        valueProperty: 'roleID',
        options: this.roleService.roles,
        test: (ref) => !get(ref, 'disabledAt'),
        func: (role: any) => this.roleService.roleName(role)
      },
      {
        containerClass: 'review approve',
        name: 'approver_permissions',
        title: this.translate.instant('MGMT_DETAILS.Permissions'),
        placeholder: this.translate.instant('SHARED.All_Permissions'),
        type: 'selectmenu',
        disabled,
        multiple: true,
        options: cloneDeep(this.permissionService.permissions),
        test: (ref) => {
          ref.description = this.translate.instant(ref.description);
          if (ref.id === 'corvex') {
            return get(this.userdataService.Permissions, 'corvex');
          } else if (ref.id === 'sadmin') {
            return get(this.userdataService.Permissions, 'sadmin') || get(this.userdataService.Permissions, 'corvex');
          } else {
            return true;
          }
        }
      },
      {
        containerClass: 'review approve',
        title: this.translate.instant('SHARED.Certifications'),
        disabled,
        name: 'approver_certifications',
        placeholder: this.translate.instant('SHARED.All_Certifications'),
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'certificationID',
        options: this.certificationsService.certifications,
        func: (certification) => this.certificationsService.certificationName(certification),
        test: (certification) => !get(certification, 'disabledAt')
      },
      {
        containerClass: 'review publish type-selector',
        title: 'CONTENT.Publisher_Prompt',
        type: 'subtitle',
        name: 'NONE'
      },
      {
        title: this.translate.instant('SHARED.Specific_Users'),
        required: false,
        disabled,
        containerClass: 'review publish',
        name: 'publishers',
        type: 'selectmenu',
        multiple: true,
        placeholder: this.translate.instant('SHARED.None'),
        valueProperty: 'userID',
        options: this.accounts,
        test: (ref) => {
          if (ref.disabledAt !== 0 || !ref.active) {
            return false;
          } else {
            return true;
          }
        },
        func: (ref) => this.userService.getFullname(ref.userID)
      },
      {
        containerClass: 'review publish',
        title: this.translate.instant('SHARED.TEAMS'),
        name: 'publisher_teams',
        disabled,
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Teams'),
        multiple: true,
        originalOrder: true,
        options: this.filterService.buildTeamMenu([], false).dropDownOptions
      },
      {
        containerClass: 'review publish',
        name: 'publisher_roles',
        title: this.translate.instant('LAYOUT.Roles'),
        placeholder: this.translate.instant('SHARED.All_Roles'),
        type: 'selectmenu',
        disabled,
        multiple: true,
        valueProperty: 'roleID',
        options: this.roleService.roles,
        test: (ref) => !get(ref, 'disabledAt'),
        func: (role: any) => this.roleService.roleName(role)
      },
      {
        containerClass: 'review publish',
        name: 'publisher_permissions',
        title: this.translate.instant('MGMT_DETAILS.Permissions'),
        placeholder: this.translate.instant('SHARED.All_Permissions'),
        type: 'selectmenu',
        disabled,
        multiple: true,
        options: cloneDeep(this.permissionService.permissions),
        test: (ref) => {
          ref.description = this.translate.instant(ref.description);
          if (ref.id === 'corvex') {
            return get(this.userdataService.Permissions, 'corvex');
          } else if (ref.id === 'sadmin') {
            return get(this.userdataService.Permissions, 'sadmin') || get(this.userdataService.Permissions, 'corvex');
          } else {
            return true;
          }
        }
      },
      {
        containerClass: 'review publish',
        title: this.translate.instant('SHARED.Certifications'),
        disabled,
        name: 'publisher_certifications',
        placeholder: this.translate.instant('SHARED.All_Certifications'),
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'certificationID',
        options: this.certificationsService.certifications,
        func: (certification) => this.certificationsService.certificationName(certification),
        test: (certification) => !get(certification, 'disabledAt')
      },
      {
        containerClass: 'review type-selector',
        title: 'CONTENT.Renew_Prompt',
        type: 'subtitle',
        name: 'NONE'
      },
      {
        title: 'CONTENT.Number_Required',
        type: 'number',
        containerClass: 'review',
        name: 'reapproval_count',
        disabled,
        required: true,
        min: 0,
        default: 0
      },
      {
        title: this.translate.instant('CONTENT.Renew_Interval'),
        type: 'timepicker',
        name: 'reapproval_interval',
        containerClass: 'review',
        disabled,
        required: false,
        searchable: false,
        originalOrder: true,
        min: 0,
        options: [
          {
            id: 'minutes',
            description: this.translate.instant('SHARED.Minutes'),
          },
          {
            id: 'hours',
            description: this.translate.instant('SHARED.Hours'),
          },
          {
            id: 'days',
            description: this.translate.instant('SHARED.Days'),
          },
          {
            id: 'weeks',
            description: this.translate.instant('SHARED.Weeks'),
          },
          {
            id: 'months',
            description: this.translate.instant('SHARED.Months'),
          },
          {
            id: 'years',
            description: this.translate.instant('SHARED.Years'),
          }
        ]
      },
      {
        title: this.translate.instant('SHARED.Specific_Users'),
        required: false,
        disabled,
        containerClass: 'review renew',
        name: 'reapprovers',
        type: 'selectmenu',
        multiple: true,
        placeholder: this.translate.instant('SHARED.None'),
        valueProperty: 'userID',
        options: this.accounts,
        test: (ref) => {
          if (ref.disabledAt !== 0 || !ref.active) {
            return false;
          } else {
            return true;
          }
        },
        func: (ref) => this.userService.getFullname(ref.userID)
      },
      {
        containerClass: 'review renew',
        title: this.translate.instant('SHARED.TEAMS'),
        name: 'reapproval_teams',
        disabled,
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Teams'),
        multiple: true,
        originalOrder: true,
        options: this.filterService.buildTeamMenu([], false).dropDownOptions
      },
      {
        containerClass: 'review renew',
        name: 'reapproval_roles',
        title: this.translate.instant('LAYOUT.Roles'),
        placeholder: this.translate.instant('SHARED.All_Roles'),
        type: 'selectmenu',
        disabled,
        multiple: true,
        valueProperty: 'roleID',
        options: this.roleService.roles,
        test: (ref) => !get(ref, 'disabledAt'),
        func: (role: any) => this.roleService.roleName(role)
      },
      {
        containerClass: 'review renew',
        name: 'reapproval_permissions',
        title: this.translate.instant('MGMT_DETAILS.Permissions'),
        placeholder: this.translate.instant('SHARED.All_Permissions'),
        type: 'selectmenu',
        disabled,
        multiple: true,
        options: cloneDeep(this.permissionService.permissions),
        test: (ref) => {
          ref.description = this.translate.instant(ref.description);
          if (ref.id === 'corvex') {
            return get(this.userdataService.Permissions, 'corvex');
          } else if (ref.id === 'sadmin') {
            return get(this.userdataService.Permissions, 'sadmin') || get(this.userdataService.Permissions, 'corvex');
          } else {
            return true;
          }
        }
      },
      {
        containerClass: 'review renew',
        title: this.translate.instant('SHARED.Certifications'),
        disabled,
        name: 'reapproval_certifications',
        placeholder: this.translate.instant('SHARED.All_Certifications'),
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'certificationID',
        options: this.certificationsService.certifications,
        func: (certification) => this.certificationsService.certificationName(certification),
        test: (certification) => !get(certification, 'disabledAt')
      },
    ]);
  }

  public getLimitContentAccessFields(): FormField[] {
    return [
      {
        labelClass: 'body-copy-bold',
        title: 'MGMT_DETAILS.Limit_Content_Access',
        name: 'limit_access',
        type: 'flipswitch',
        onText: this.translate.instant('SHARED.On'),
        offText: this.translate.instant('SHARED.Off'),
        value: 1,
        hideFieldsOnValue: {
          select: [{
            alwaysHide: true,
            hideClasses: ['access'],
            value: 0,
            // valueFunction: (val) => {
            //   return val.length === 0;
            // }
            showClassesFunc: (value) => {
              const showClasses = [];
              if (value) {
                showClasses.push('access');
              }
              return showClasses;
            }
          }]
        }
      },
      {
        title: this.translate.instant('SHARED.Locations'),
        containerClass: 'access',
        name: 'access_locations',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Locations'),
        multiple: true,
        valueProperty: 'locationID',
        options: this.locations,
        func: (ref) => ref.name,
        onChange: (value, form, formConfig) => this.onAccessLocationChange(formConfig)
      },
      {
        containerClass: 'access',
        title: this.translate.instant('SHARED.TEAMS'),
        name: 'access_teams',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Teams'),
        multiple: true,
        originalOrder: true,
        options: []
      },
      {
        containerClass: 'access',
        name: 'access_shifts',
        title: this.translate.instant('SHARED.Shift'),
        type: 'selectmenu',
        placeholder: this.translate.instant('OTable.All_Shifts'),
        multiple: true,
        originalOrder: true,
        options: []
      },
      {
        containerClass: 'access',
        name: 'access_roles',
        title: this.translate.instant('LAYOUT.Roles'),
        placeholder: this.translate.instant('SHARED.All_Roles'),
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'roleID',
        options: this.roleService.roles,
        test: (ref) => !get(ref, 'disabledAt'),
        func: (role: any) => this.roleService.roleName(role)
      },
      {
        containerClass: 'access',
        name: 'access_permissions',
        title: this.translate.instant('MGMT_DETAILS.Permissions'),
        placeholder: this.translate.instant('SHARED.All_Permissions'),
        type: 'selectmenu',
        multiple: true,
        options: cloneDeep(this.permissionService.permissions),
        test: (ref) => {
          ref.description = this.translate.instant(ref.description);
          if (ref.id === 'corvex') {
            return get(this.userdataService.Permissions, 'corvex');
          } else if (ref.id === 'sadmin') {
            return get(this.userdataService.Permissions, 'sadmin') || get(this.userdataService.Permissions, 'corvex');
          } else {
            return true;
          }
        }
      },
      {
        containerClass: 'access',
        title: this.translate.instant('SHARED.Certifications'),
        name: 'access_certifications',
        placeholder: this.translate.instant('SHARED.All_Certifications'),
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'certificationID',
        options: this.certificationsService.certifications,
        func: (certification) => this.certificationsService.certificationName(certification),
        test: (certification) => !get(certification, 'disabledAt')
      }
    ];
  }

  public encodeReviewFields(formData: IObjectStringKeyMap<any>, structure: string = 'attributes.reviewInfo') {
    const numFields = [
      'auto_approve',
      'auto_reject',
      'auto_publish',
      'reapproval_interval',
      'review_duration',
      'reapproval_count',
      'reviewer_count',
      'document_review'
    ];

    const stringFields = [
      'reapproval_interval_unit',
      'review_duration_unit',
    ];
    const aryFields = [
      'review_certifications',
      'review_locations',
      'review_roles',
      'review_teams',
      'review_permissions',
      'reviewers',
      'approver_certifications',
      'approver_locations',
      'approver_roles',
      'approver_teams',
      'approver_permissions',
      'approvers',
      'publisher_certifications',
      'publisher_locations',
      'publisher_roles',
      'publisher_teams',
      'publisher_permissions',
      'publishers',
      'reapprover_certifications',
      'reapprover_locations',
      'reapprover_roles',
      'reapprover_teams',
      'reapprover_permissions',
      'reapprovers'
    ];

    each(stringFields, (fieldName) => {
      set(formData, `${structure}.${fieldName}`, has(formData, fieldName) ? formData[fieldName] : "" );
      delete formData[fieldName];
    });
    each(aryFields, (fieldName) => {
      set(formData, `${structure}.${fieldName}`, has(formData, fieldName) ? this.utils.makeArray(formData[fieldName], parseInt) : [] );
      delete formData[fieldName];
    });
    each(numFields, (fieldName) => {
      set(formData, `${structure}.${fieldName}`, has(formData, fieldName) ? +formData[fieldName] : 0 );
      delete formData[fieldName];
    });
  }

  private getParentIds(folderData: IFolder): SelectedProperty[] {
    let parentProperties: SelectedProperty[] = [];

    if (folderData?.parentID) {
      if (folderData?.parentAttributes?.selectedProperties) {
        parentProperties = folderData.parentAttributes.selectedProperties as SelectedProperty[];
      } else {
        const parentFolder = this.foldersDataService.getFolderByID(+folderData.parentID);
        parentProperties = [
          ...parentFolder?.attributes?.selectedProperties || [],
          ...parentFolder?.parentAttributes?.selectedProperties || []
        ] as SelectedProperty[];
      }
    }

    return parentProperties;
  }

}
