import { Component, HostListener, Input } from '@angular/core';

import { ViewerService } from '@services/viewer/viewer.service';
import { includes } from 'lodash';


@Component({
  selector: 'app-media-file',
  templateUrl: './media-file.component.html',
  styleUrls: ['./media-file.component.scss'],
})
export class MediaFileComponent {

  @Input() disableOpening: boolean;
  @Input() url: string;
  @Input() class: string;
  public isVideo: boolean;
  public isPDF: boolean;
  private fileMediaType: string;

  constructor(private viewerService: ViewerService) {
  }

  @Input() set mediaType(value: string) {
    this.isVideo = includes(value, 'video/');
    this.isPDF = includes(value, '/pdf');
    this.fileMediaType = value;
  }

  @HostListener('click', ['$event']) onClick() {
    if (!this.disableOpening) {
      this.viewerService.showByType(this.url.replace('/thumb/', '/object/'), this.fileMediaType);
    }
  }
}
