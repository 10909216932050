import { Injectable } from '@angular/core';

import { CommsService } from '@services';
import { IObjectStringKeyMap } from '@shared/models';
import { Events } from '@services/events/events.service';
import { FolderDataType, IFolder, ITreeFolder } from '@modules/management/modules/folders/model/folders.interfaces';
import { BaseService } from '@services/abstract-base-service/abstract-base-service.service';

import { cloneDeep, each, filter, flattenDeep, includes, indexOf, isUndefined, join, map, some } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FoldersDataService extends BaseService {

  private folders: { data: IObjectStringKeyMap<IFolder>; lastRequest: number } = {
    lastRequest: null,
    data: {}
  };

  constructor(
    protected commsService: CommsService,
    private events: Events,
  ) {
    super(commsService);
  }

  public refresh() {
    return new Promise((resolve, reject) => {
      this.commsService.sendMessage({
        cmd: 'getFolders',
        includeDisabled: 1,
      }, false, false).then(data => {
        if (data && data.reqStatus === 'OK') {
          this.updateCache(data);
        }
        resolve(this.folders.data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public updateCache(data) {
    this.folders.lastRequest = data.result.timestamp;
    this.folders.data = data.result.folders;
    this.events.publish('ccs:foldersUpdate');
  }

  public addFolder(parameters: any) {
    const requestParameters = {
      cmd: 'addFolder',
      ...parameters
    };

    return this.handleRequest(requestParameters);
  }

  public deleteFolder(parameters: any) {
    const requestParameters = {
      cmd: 'deleteFolder',
      ...parameters
    };

    return this.handleRequest(requestParameters);
  }

  public updateFolder(parameters: any): any {
    const requestParameters = {
      cmd: 'updateFolder',
      ...parameters
    };

    return this.handleRequest(requestParameters);
  }

  public getFolders(types?: string[]) {
    const folders: IObjectStringKeyMap<IFolder> = {};

    each(this.folders.data, (folder, id) => {
      const isActive = folder.disabledAt === 0;

      if (isActive) {
        const filterByType = !!types?.length;
        const isMatched = indexOf(types, folder.type) > -1;

        if ((filterByType && isMatched) || !filterByType) {
          folders[id] = folder;
        }
      }
    });

    return folders;
  }

  public getFolderTreeBy(type: FolderDataType): IObjectStringKeyMap<ITreeFolder> {
    const folders: IObjectStringKeyMap<ITreeFolder> = cloneDeep(this.getFolders([type]));

    each(folders, (folder) => {
      const parentFolder = folders[folder.parentID];

      if (parentFolder) {
        if (!parentFolder.children) {
          parentFolder.children = [];
        }

        parentFolder.children.push(folder);
      }
    });

    each(folders, (folder) => {
      folder.childrenIds = this.extractFolderIds(folder.children);
    });

    return folders;
  }

  public hasIn(parentFolderId: number, childFolderId: number, folderTree: IObjectStringKeyMap<ITreeFolder>): boolean {
    if (parentFolderId === childFolderId) {
      return true;
    } else {
      if (parentFolderId === 0) {
        return !!folderTree[childFolderId];
      } else {
        return includes(folderTree[parentFolderId]?.childrenIds, childFolderId);
      }
    }
  }

  public hasInFolders(targetFolder: number, folders: number[], folderTree: IObjectStringKeyMap<ITreeFolder>) {
    return some(folders, (folderID: number) => {
      return this.hasIn(folderID, targetFolder, folderTree) || this.hasIn(targetFolder, folderID, folderTree);
    });
  }

  private extractFolderIds(children: ITreeFolder[]): number[] {
    const folders = flattenDeep(map(children, (folder) => {
      folder.childrenIds = this.extractFolderIds(folder.children || []);
      return [folder, ...folder.childrenIds];
    }));

    return map(folders, (folder) => {
      return (folder as ITreeFolder)?.folderID || folder as number;
    });
  }


  public isActive(folderID: number): boolean {
    const folder = this.getFolderByID(folderID, true);
    if (!folder) {
      return false;
    } else {
      return true;
    }
  }

  public getFolderPath(folderID: number, mustBeActive: boolean = false, separator?: string) {
    const path = [];
    let folder = this.getFolderByID(folderID, mustBeActive);
    while (folder) {
      path.push(folder.title);
      folder = this.getFolderByID(folder.parentID);
    }
    path.reverse();

    if (separator) {
      return join(path, separator);
    } else {
      return path;
    }
  }

  public getFoldersAsync(types: string[]) {
    return new Promise((resolve, reject) => {
      this.commsService.sendMessage({
        cmd: 'getFolders',
        includeDisabled: 1,
        types,
      }, false, false).then(data => {
        if (data && data.reqStatus === 'OK') {
          this.folders.lastRequest = data.result.timestamp;
          this.folders.data = data.result.folders;
          this.events.publish('ccs:foldersUpdate');
        }
        resolve(this.folders.data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public getFolderByID(folderID: number, active = true): IFolder {
    const folder = this.folders.data[folderID];

    if (active) {
      return folder?.disabledAt === 0 ? folder : undefined;
    }

    return folder;
  }

  public getFoldersByType(folderType: FolderDataType, active = true): IFolder[] {
    const filterHandler: IObjectStringKeyMap<number | FolderDataType> = { type: folderType };

    if (active) {
      filterHandler.disabledAt = 0;
    }

    return filter(this.folders.data, filterHandler);
  }

  public getFolderTree(folderType: FolderDataType, folderID: number = 0, folderTree?: IFolder[]): IFolder[] {
    if (isUndefined(folderTree)) {
      folderTree = this.getFoldersByType(folderType);
    }

    let ret = [];

    if (folderID) {
      // we need a subset
      // first, capture THIS folder
      ret = filter(folderTree, { folderID });
      // now see if it has any children
      const children = filter(folderTree, { parentID: folderID });
      if (children.length) {
        // it does!  capture them and any children they have recursively
        each(children, fid => {
          const rollup = this.getFolderTree(folderType, fid.folderID, folderTree);
          each(rollup, item => {
            ret.push(item);
          });
        });
      }
    } else {
      ret = folderTree;
    }
    return ret;
  }
}
