import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-navigation',
  templateUrl: './back-navigation.component.html',
  styleUrls: ['./back-navigation.component.scss'],
})
export class BackNavigationComponent {

  @Input() pageTitle: string;
  @Input() navigationTitle: string;
  @Input() pageSubTitle: string;
  @Input() noTextTransform: boolean;

  @Output() onBack: EventEmitter<void> = new EventEmitter<void>();

  constructor(private location: Location) {
  }

  public back(): void {
    this.onBack.observers.length ? this.onBack.emit() : this.location.back();
  }

}
