import { Directive, ElementRef, HostListener, Input, OnChanges, TemplateRef } from '@angular/core';

import { IObjectStringKeyMap } from '@shared/models';
import { DynamicComponentCreatorService } from '@services';
import { TooltipComponent } from './../components/tooltip/tooltip.component';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnChanges {
  @HostListener('mouseenter') onMouseEnter(): void {
    if (!this.tooltipComponentRef) {
      this.dynamicComponentCreatorService.create(TooltipComponent, (tooltipComponent: TooltipComponent) => {
        this.tooltipComponentRef = tooltipComponent;
        this.setTooltipProperties();
      }, document.body, true);
    }
  };
  @HostListener('mouseleave') onMouseLeave(): void {
    this.destroy();
  };
  @Input() tooltipClass: string;
  @Input() tooltip: string | number | IObjectStringKeyMap<any>;
  @Input() tooltipTemplate: TemplateRef<HTMLElement>;

  private tooltipComponentRef: TooltipComponent;

  constructor(
    private elementRef: ElementRef,
    private dynamicComponentCreatorService: DynamicComponentCreatorService
  ) {}

  ngOnDestroy(): void {
    this.destroy();
  }

  ngOnChanges() {
    this.setTooltipProperties();
  }

  destroy(): void {
    this.dynamicComponentCreatorService.destroy(TooltipComponent);
    this.tooltipComponentRef = null;
  }

  private setTooltipProperties() {
    if (this.tooltipComponentRef) {
      this.tooltipComponentRef.tooltip = this.tooltip;
      const {left, right, top} = this.elementRef.nativeElement.getBoundingClientRect();
      this.tooltipComponentRef.left = (right - left) / 2 + left;
      this.tooltipComponentRef.bottom = window.innerHeight - top + 5;
      this.tooltipComponentRef.tooltipClass = this.tooltipClass;
      this.tooltipComponentRef.template = this.tooltipTemplate;
    }
  }
}
