import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-note-card',
  templateUrl: './note-card.component.html',
  styleUrls: ['./note-card.component.scss'],
})
export class NoteCardComponent {
  @Input() avatarUrl: string;
  @Input() name: string;
  @Input() time: string;
  @Input() text: string;
  @Input() type: string;
  @Input() headerPostText: string[];
  @Input() headerPostTextClass: string;
}
