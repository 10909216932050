<button (click)="open()"
        class="shared-link body-link button-link active-link ion-activatable"
        (mouseleave)="resetTooltip()"
        [tooltipTemplate]="tooltipRef"
        [tooltip]="tooltipText | translate">
  {{ "SHARED.Copy_Link" | translate }}
  <ion-ripple-effect></ion-ripple-effect>
</button>

<ng-template #tooltipRef>
  <ion-spinner *ngIf="loading; else tooltipTextRef" name="lines-sharp-small"></ion-spinner>
  <ng-template #tooltipTextRef>
    {{tooltipText | translate}}
  </ng-template>
</ng-template>
