import { Component, HostBinding, TemplateRef } from '@angular/core';

import { IObjectStringKeyMap } from '@shared/models';

type Tooltip = string | number | IObjectStringKeyMap<any>;

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @HostBinding('class') tooltipClass: string;
  public tooltip: Tooltip = '';
  public template?: TemplateRef<Tooltip>;
  public left: number = 0;
  public bottom: number = 0;
}
