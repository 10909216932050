import { Component, Input } from '@angular/core';

import { SubscriberService } from '@services';

export interface ICopyConfig {
  url?: string; // 'pages/dashboard/condition-detail'
  description?: string;
  getUrl?: () => Promise<string>;
}

@Component({
  selector: 'app-copy-link',
  templateUrl: './copy-link.component.html',
  styleUrls: ['./copy-link.component.scss'],
})
export class CopyLinkComponent {
  @Input() config: ICopyConfig;
  public tooltipText: string
  public loading: boolean;

  constructor(private subscriberService: SubscriberService) {
    this.resetTooltip();
  }

  public async open() {
    let url = this.config.url;
    if (this.loading) {
      return;
    }

    if (this.config.getUrl) {
      this.loading = true;
      url = await this.config.getUrl();
      this.loading = false;
    }

    this.tooltipText = 'SHARED.Copied';
    navigator.clipboard.writeText(`${this.subscriberService.baseURL()}/${url}`);
  }

  public resetTooltip() {
    this.tooltipText = 'SHARED.Copy_Link';
  }
}
