import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AuthService, ObjectsService } from '@services';
import { DeepLinkGuard } from '@services/deep-link/deep-link.service';

import { includes } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ContentLinkGuard implements CanActivate, DeepLinkGuard {

  private readonly baseUrl = 'pages/management/content';
  private readonly listUrl = `${this.baseUrl}-list`;
  private readonly documentUrl = `${this.baseUrl}/document/`;

  constructor(
    private router: Router,
    private objectsService: ObjectsService,
    private authService: AuthService
  ) {}

  async canActivate(next: ActivatedRouteSnapshot) {
    const folderID: string = next.paramMap.get('folderID');
    const objectID: string = next.paramMap.get('objectID');
    const rowID: string = next.paramMap.get('rowID');

    if (this.authService.isUserLogged()) {
      await this.router.navigate([this.listUrl]);
    }

    if (folderID) {
      return this.navigateToFolderPage(+folderID);
    } else if (rowID) {
      return this.navigateToRevisionPage(+objectID, +rowID);
    } else if (objectID) {
      return this.navigateToContentPage(+objectID);
    }
  }

  public async navigateToContentPage(objectID: number) {
    return this.router.navigate([`${this.documentUrl}/${objectID}`]).then(() => {
      return false;
    }).catch(() => this.navigateToMainPage());
  }

  public async navigateToRevisionPage(objectID: number, rowID: number) {
    const folderID = this.objectsService.getCachedObjectById(objectID)?.folderID;
    return this.router.navigate([`${this.documentUrl}/${objectID}/revision/${objectID}/${rowID}`, { folderID }]).then(() => {
      return false;
    }).catch(() => this.navigateToMainPage());
  }

  public async navigateToFolderPage(folderId: number) {
    return this.router.navigate([this.listUrl, { folderId }]).then(() => {
      return false;
    }).catch(() => this.navigateToMainPage());
  }

  public parseUrlId(url: string): string {
    if (includes(url, this.baseUrl)) {
      return url;
    }
  }

  public async open(url: string) {
    await this.router.navigate([this.listUrl]);
    this.router.navigateByUrl(url);
  }

  private navigateToMainPage(): boolean {
    this.router.navigate([this.listUrl]);
    return false;
  }
}
