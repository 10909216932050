import { PopoverController } from '@ionic/angular';
import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';

import { TeamsService, UtilsService } from '@services';
import { Events } from '@services/events/events.service';
import { Asset } from '@services/assets/asset.interfaces';
import { DatePickerPopupComponent } from '@shared/components';
import { FolderPickerSelection, IFolder } from '@modules/management/modules/folders/model/folders.interfaces';

import { TranslateService } from '@ngx-translate/core';
import {
  cloneDeep,
  each,
  filter,
  find,
  get,
  has,
  includes,
  intersection,
  isEmpty,
  lowerCase,
  map,
  pull,
  reject,
  remove,
  size,
  split
} from 'lodash';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {

  public filterComps: any;
  // showFilterBody - to keep buttons active/inactive
  @Input() showFilterBody: boolean;
  // reference of the filter object being used currently, having it here prevents the edge cases
  @Input() filterObject: any = {};
  public closeText = isEmpty(this.filterObject) ? 'Close' : this.translate.instant('SHARED.APPLY_CHANGES');
  @Input() disableRemoval: boolean;
  @Input() title: string;
  @Output() onSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onRemoveItem: EventEmitter<any> = new EventEmitter<any>();
  filterAvailable = false;
  imageMap = {
    highest: 'assets/images/highestDot.svg',
    high: 'assets/images/highDot.svg',
    medium: 'assets/images/mediumDot.svg',
    low: 'assets/images/lowDot.svg',
    lowest: 'assets/images/lowestDot.svg'
  };
  labelMap = {
    Location: 'SHARED.Location(s)',
    Zone: 'SHARED.Zone(s)',
    User: 'SHARED.User(s)',
    Team: 'SHARED.Team(s)',
    Shift: 'SHARED.Shift(s)',
    Certifications: 'SHARED.Certification(s)'
  };
  private originFilterComps: any;
  private isSubscribed = false;

  constructor(
    private events: Events,
    private utils: UtilsService,
    private popover: PopoverController,
    private teamsService: TeamsService,
    private translate: TranslateService,
    private elementRef: ElementRef
  ) {
  }

  // filter components - data for what drop down filter to show with options name and id
  @Input('filterComps')
  set setFilterComps(filterComps: any) {
    this.filterComps = filterComps;
    this.originFilterComps = cloneDeep(filterComps);
  }

  // ngOnInit() {
  //   if ( (this.events as any).c.get('customDate')) {
  //       return false;
  //   } else {
  //     this.events.subscribe('customDate', data => {
  //       this.filterObject.startTime = data.startDate * 1000;
  //       this.filterObject.endTime = data.endDate * 1000;
  //       this.events.publish('ccs:filterObject', this.filterObject);
  //     });
  //   }
  // }

  ngOnInit() {
    this.closeText = isEmpty(this.filterObject) ? 'Close' : this.translate.instant('SHARED.APPLY_CHANGES');
  }

  dropDownChanged(event, fName) {
    // check which drop down changed.
    switch (fName) {
      case 'Location': {
        this.filterObject.locations = map(event.value, (val) => Number(val));
        event.value = [];

        const originalFilterZoneComponent: any = find(this.originFilterComps, {name: 'Zone'});
        const currentFilterZoneComponent: any = find(this.filterComps, {name: 'Zone'});

        let filteredZoneOptions: any[] = [];

        if (originalFilterZoneComponent) {
          if (this.filterObject.locations.length === 0) {
            filteredZoneOptions = originalFilterZoneComponent.dropDownOptions;
          } else {
            each(this.filterObject.locations, (locationId: number) => {
              filteredZoneOptions.push(find(originalFilterZoneComponent.dropDownOptions, [`children[0].locationID`, locationId]));
            });

            let zoneIdsBySelectedLocations: string[] | number[] = <string[] | number[]>[];

            each(this.filterObject.locations, (location: number) => {
              const selectedLocation: any = find(currentFilterZoneComponent.dropDownOptions, ['children[0].id', `${location}:0`]);
              const zonesBySelectedLocation: any = get(selectedLocation, 'children');

              zoneIdsBySelectedLocations = <string[] | number[]>[
                ...zoneIdsBySelectedLocations,
                ...map(zonesBySelectedLocation, 'id')
              ];
            });

            this.filterObject.zones = filter(this.filterObject.zones, (zone: number | string) => includes(zoneIdsBySelectedLocations, zone));
          }

          Object.assign(currentFilterZoneComponent, {
            dropDownOptions: filteredZoneOptions,
            value: map(this.filterObject.zones, String)
          });
        }

        this.defineShifts();
        this.defineTeams();
        break;
      }
      case 'Team': {
        this.filterObject.groups = map(event.value, (val) => Number(val));
        break;
      }
      case 'Certifications': {
        this.filterObject.certifications = map(event.value, (val) => Number(val));
        break;
      }
      case 'Role': {
        this.filterObject.roles = map(event.value, (val) => Number(val));
        break;
      }
      case 'User': {
        this.filterObject.users = map(event.value, (val) => Number(val));
        break;
      }
      case 'Timespan': {
        if (event.value === 'custom') {
          $(this.elementRef.nativeElement).find('select').val([]);
          this.popupTime();
          this.filterObject.period = 'days';
          this.filterObject.timespan = event.value;
        } else {
          const timespan = this.utils.timespan(event.value);
          let timePeriod = 'days';
          if (event.value === 'today') {
            timePeriod = 'hours';
          } else if (event.value === '90days') {
            timePeriod = 'weeks';
          } else if (event.value === '365days') {
            timePeriod = 'months';
          }
          this.filterObject.startTime = timespan.startTime;
          this.filterObject.endTime = timespan.endTime;
          this.filterObject.period = timePeriod;
          this.filterObject.timespan = event.value;
        }
        break;
      }
      case 'Zone': {
        // but we have to do some cleaning here thoo..
        this.filterObject.zones = map(event.value, (val) => val);
        break;
      }
      case 'S + L': {
        this.filterObject.likelihoods = [];
        this.filterObject.severities = [];
        // check event data for text type and put it in severity and likelihood
        each(event.data, val => {
          if (val.text === 'Sev') {
            const rangeVal = split(val.id, '-')[0];
            this.filterObject.severities.push(lowerCase(rangeVal));
          } else if (val.text === 'Lik') {
            const rangeVal = split(val.id, '-')[0];
            this.filterObject.likelihoods.push(lowerCase(rangeVal));
          }
        });
        break;
      }
      case 'Account Type': {
        this.filterObject.accountType = event.value;
        break;
      }
      case 'User Status': {
        this.filterObject.active = event.value;
        break;
      }
      case 'Permission Level': {
        this.filterObject.permissionLevel = event.value;
        break;
      }
      case 'Shift': {
        this.filterObject.shift = event.value;
        break;
      }
      case 'Status': {
        this.filterObject.status = event.value;
        break;
      }
    }
    if (!isEmpty(this.filterObject)) {
      this.closeText = this.translate.instant('SHARED.APPLY_CHANGES');
    } else {
      this.closeText = this.translate.instant('DASHBOARD.Close');
    }
    this.events.publish('ccs:filterObject', this.filterObject);
  }

  clearAll() {
    if (size(this.filterObject) === 1) {
      if (this.filterObject.timespan === 'all') {
        return false;
      }
    }
    each(this.filterComps, comp => {
      comp.value = [];
    });
    each(this.filterObject, (value, key) => {
      delete this.filterObject[key];
    });
    $('.observation-search-field').val(null);
    this.events.publish('ccs:filterObject', this.filterObject);
    this.events.publish('ccs:clearMenu', true);
    this.filterAvailable = false;
    this.closeText = this.translate.instant('DASHBOARD.Close');
  }


  closeFilter() {
    this.showFilterBody = false;
    this.onSelected.emit(true);
    this.events.publish('ccs:clearMenu', true);
  }

  removeThis(name, val) {
    if (name === 'timespan') {
      this.filterObject[name] = 'all';
      {
        const timespan = this.utils.timespan('all');
        this.filterObject.startTime = timespan.startTime;
        this.filterObject.endTime = timespan.endTime;
        this.filterObject.timespan = 'all';
        delete this.filterObject.period;
      }
    } else if (name === 'zones') {
      this.filterObject[name] = remove(this.filterObject[name], valFromArr => valFromArr.toString() !== val);
    } else if (name === 'sl') {
      const obsType = split(val, '-');
      if (obsType[1] === 'L') {
        this.filterObject.likelihoods = remove(this.filterObject.likelihoods, valFromArr => valFromArr !== (obsType[0]));
      } else {
        this.filterObject.severities = remove(this.filterObject.severities, valFromArr => valFromArr !== (obsType[0]));
      }

    } else if (includes(['accountType', 'permissionLevel'], name)) {
      this.filterObject[name] = pull(this.filterObject[name], val);
    } else if (name === 'status') {
      delete this.filterObject[name];
    } else if (name === 'shift') {
      this.filterObject[name] = pull(this.filterObject[name], val);
    } else if (name === 'groups') {
      this.filterObject[name] = pull(this.filterObject[name], +val);
    } else if (name === 'assetsFolders') {
      this.filterObject[name] = reject(this.filterObject[name], (filterItem: IFolder | Asset) => {
        return filterItem.folderID === val || (filterItem as Asset).assetID === val;
      });
    } else if (name === 'folders') {
      this.filterObject[name] = reject(this.filterObject[name], (filterItem: FolderPickerSelection) => {
        return filterItem.folderID === val || filterItem.itemID === val;
      });
    } else {
      this.filterObject[name] = remove(this.filterObject[name], (valFromArr) => {
        val = isNaN(+val) ? val : +val;
        return valFromArr !== val;
      });

      if (name === 'locations') {
        this.defineShifts();
        this.defineTeams();
      }
    }

    this.onRemoveItem.emit(this.filterObject);
    this.events.publish('ccs:filterObject', this.filterObject);
    this.events.publish('ccs:clearMenu', true);
  }

  public findFilterLabel(item, dropDownOptions) {
    each(dropDownOptions, (dropDownOption) => {
      dropDownOption.id = isNaN(+dropDownOption.id) ? dropDownOption.id : +dropDownOption.id;
    });
    let filtObj = find(dropDownOptions, ['id', Number(item)]);
    if (filtObj) {
      return filtObj.text;
    }
    if (!filtObj) { // could be time
      filtObj = find(dropDownOptions, ['id', (item)]);
      if (filtObj) {
        return filtObj.text;
      }
    }

    // let's check for the name of zones, if option has children property then we know its nested
    if (has(dropDownOptions[0], 'children') && !includes(['severity', 'likelihood'], dropDownOptions[0].id)) {
      let zName = null;
      each(dropDownOptions, optGrp => {
        if (!includes(item, ':') && !this.checkUUID(item)) {
          item = Number(item);
        }
        const zoneObj = find(optGrp.children, ['id', (item)]);
        if (zoneObj) {
          zName = optGrp.text + ' - ' + zoneObj.text;
          return false;
        }
      });
      return zName;
    } else if (has(dropDownOptions[0], 'children') && includes(['severity', 'likelihood'], dropDownOptions[0].id)) {
      const rangeVal = split(item, '-')[0];
      const imgDot = this.imageMap[rangeVal];
      return imgDot;
    }
  }

  findColorLabelName(item) {
    const rangeVal = split(item, '-');
    if (rangeVal[1] === 'L') { // likelihood
      return this.translate.instant('SHARED.Likelihood');
    } else { // severity
      return this.translate.instant('SHARED.Severity');
    }
  }

  public filterValuesAvailable() {
    this.filterAvailable = false;
    each(this.filterComps, comp => {
      if (comp.value && comp.value.length > 0) {
        this.filterAvailable = true;
        return false;
      }
    });
    return this.filterAvailable;
  }

  public mapLabel(label) {
    if (this.labelMap[label]) {
      return this.translate.instant(this.labelMap[label]);
    } else {
      return label;
    }
  }

  private defineShifts(): void {
    const originalFilterShiftComponent: any = find(this.originFilterComps, {name: 'Shift'});
    const currentFilterShiftComponent: any = find(this.filterComps, {name: 'Shift'});

    if (originalFilterShiftComponent) {
      const options: any[] = get(this.filterObject, 'locations.length') ? filter(originalFilterShiftComponent.allOptions, (shift: any) => includes(this.filterObject.locations, shift.locationId)) : originalFilterShiftComponent.allOptions;

      const ids: string[] = map(options, 'id');
      this.filterObject.shift = intersection(ids, this.filterObject.shift);

      Object.assign(currentFilterShiftComponent, {
        dropDownOptions: options,
        value: this.filterObject.shift
      });
    }
  }

  private defineTeams(): void {
    const originalFilterTeamComponent: any = find(this.originFilterComps, {name: 'Team'});
    const currentFilterTeamComponent: any = find(this.filterComps, {name: 'Team'});

    if (originalFilterTeamComponent) {
      const options: any[] = get(this.filterObject, 'locations.length') ? filter(originalFilterTeamComponent.dropDownOptions, (teamOption: any) => intersection(this.filterObject.locations, teamOption.id).length > 0) : originalFilterTeamComponent.dropDownOptions;

      const ids: string[] = map(options, 'id');
      this.filterObject.groups = intersection(ids, this.filterObject.groups);

      Object.assign(currentFilterTeamComponent, {
        dropDownOptions: options,
        value: map(this.filterObject.groups, String)
      });
    }
  }

  private popupTime() {
    this.popover.create(<any>{
      component: DatePickerPopupComponent,
      animated: false,
      componentProps: {
        filterObject: this.filterObject,
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  private checkUUID(uuid) {
    let s: any = '' + uuid;

    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
  }

}
