import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { GuardGuard } from '@services/guard/guard.guard';

export const SHARED_LINK = 'shared-link';

const routes: Routes = [
  {
    path: 'content',
    loadChildren: () => import('./../management/pages/details/content/link-content.module').then( m => m.LinkContentModule)
  },
  {
    path: 'observation',
    loadChildren: () => import('./../observation/link-observation.module').then( m => m.LinkObservationModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'reporting',
    loadChildren: () => import('./../reporting/link-reporting.module').then( m => m.LinkReportingModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'check-detail',
    loadChildren: () => import('./../observation/pages/check-detail/link-check-detail.module').then( m => m.LinkCheckDetailModule),
    canActivate: [GuardGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule.forChild(routes),
  ]
})
export class SharedLinkModule {
}
