<div class="page-title">{{ title | translate }}</div>

<div *ngFor="let historyItem of history" class="history-block">
  <div class="history-card">
    <div class="history-start">
      <app-note-card [avatarUrl]="historyItem.userImg" [name]="historyItem.name">
      </app-note-card>
      <div class="history-changes" *ngIf="historyItem?.relatedData?.label"><span class="history-changes-label">{{
          historyItem.relatedData.label }}</span>:
        <p class="history-changes-value" [innerHTML]="historyItem.relatedData.value"></p>
      </div>
    </div>
    <ion-button class="history-message page-button" color="secondary" [appAccountCanViewExcept]="['viewer']"
      (click)="sendMessageTo(historyItem)" *ngIf="historyItem.userID > 0 && historyItem.userActive">
      {{ "SHARED.Message" | translate }}
    </ion-button>
  </div>

  <div class="card-footer">
    <div class="card-type">
      <span>{{ historyItem.activity | observationHistoryEvent:historyItem.relatedType}}</span>
      <span *ngIf="!historyItem.linkRoute">{{ historyItem.linkTitle }}</span>
      <a class="body-link" *ngIf="historyItem.linkRoute" (click)="navigateToLink(historyItem)">
        {{ historyItem.linkTitle }}
      </a>
    </div>
    <div class="card-line"></div>
    <div class="card-time">{{ historyItem.time }}</div>
  </div>
</div>
