import { Injectable, InjectionToken, Type } from '@angular/core';

import { FormConfig, FormField } from '@services';
import { IObjectStringKeyMap } from '@shared/models';
import { FilterData } from '@shared/modules/menu-filter/services/filter-menu.service';

@Injectable()
export abstract class FolderFactoryService {
  public filterData: FilterData = {};
  public abstract getFields(folderData?: IObjectStringKeyMap<any>): FormField[];
  public prepareDataBeforeRender?(folderData?: IObjectStringKeyMap<any>): void;
  public onFinishRenderAddFolderForm?(formConfig: FormConfig): void;
  public getCopyFolderLink?(folderID: number): string;
}

export const FOLDER_FACTORY_SERVICE_TOKEN = new InjectionToken<Type<FolderFactoryService>>('FOLDER_FACTORY_SERVICE');
