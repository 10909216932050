import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { PopupObservationService } from '@services/observationDetail/popup-observation.service';

export interface INotePopupConfig {
  title?: string;
  placeHolder?: string;
  onAdd?: (textMessage: string) => void;
}

@Component({
  selector: 'app-note-popup',
  templateUrl: './note-popup.component.html',
  styleUrls: ['./note-popup.component.scss'],
})
export class NotePopupComponent {
  @Input() observationID: number;
  @Input() msgType: string;
  @Input() flagID: number;
  @Input() config: INotePopupConfig;

  public textMessage = '';
  public title = 'DASHBOARD.NOTE_POPUP_title';
  public placeHolder = 'DASHBOARD.Add_observation_note';

  constructor(
    private popOver: PopoverController,
    private popOps: PopupObservationService,
  ) {}

  ngOnInit() {
    if (this.config?.title) {
      this.title = this.config?.title;
    }
    if (this.config?.placeHolder) {
      this.placeHolder = this.config?.placeHolder;
    }
  }

  public close(): void {
    this.popOver.dismiss();
  }

  public addNotes(): void {
    if (this.config?.onAdd) {
      this.config?.onAdd(this.textMessage);
      this.popOver.dismiss();
    } else if (this.observationID) {
      this.popOps.addNote(this.observationID, this.msgType, this.textMessage);
      this.popOver.dismiss();
    } else if (this.flagID) {
      this.popOps.addIssueNote(this.flagID, this.textMessage);
      this.popOver.dismiss();
    }
  }
}
