<div class="detail-header-wrapper">

  <div *ngIf="headerData.oid" class="detail-header">
    <div *ngIf="reportId" class="report-navigation">
      <a (click)="backToReport()" class="link">{{ "SHARED.DETAIL_HEADER_backToReport" | translate }}</a>
      <div class="splitter">/</div>
      <a (click)="backToReportDetail()" class="link">{{ "SHARED.DETAIL_HEADER_backToReportDetail" | translate }}</a>
      <div class="splitter">/</div>
    </div>

    <app-observation-title [id]="headerData.oid" [isHidden]="headerData.hideNumber"
                           [title]="headerData.title"></app-observation-title>

    <div *ngIf="headerData.workOrder" class="workorder">
      <span class="workorder-title">
        {{ "SHARED.WO" | translate }}
      </span>
      <span class="workorder-box-text" id="validWorkorder">
              <span class="workorder-box-sign">#</span>
      <span class="workorder-number" id="workorderNumber">{{ headerData.workOrder }}</span>
      </span>
      <button (click)="woEdit(headerData.oid, headerData.workOrder)" [appPermissionCanView]="'supervisor'"  [appAccountCanViewExcept]="['viewer']"
              class="workorder-edit button-link">
        <span class="body-link" id="editWO">{{ "DASHBOARD.DETAIL_HEADER_Edit_WO" | translate }}</span>
      </button>
    </div>

    <span [appAccountCanViewExcept]="['viewer']" class="shared-link-wrapper" [class.disabled]="!headerData.uuid">
      <app-copy-link [config]="copyConfig"></app-copy-link>
    </span>
  </div>


  <div *ngIf="!headerData.oid" class="detail-header-check">
    <span class="detail-header-headline headline-large">{{ headerData.title }}</span>

    <app-copy-link class="copy-link" *ngIf="headerData.copyLink" [config]="headerData.copyLink"></app-copy-link>

    <ion-button *ngIf="headerData?.exportPDF" class="page-button" color="light" (click)="print()">
      {{'SHARED.Save_Download_PDF' | translate}}
    </ion-button>
  </div>
</div>

<div class="header-table-wrap">
  <table class="header-table">
    <tr>
      <td *ngFor="let headerElement of headerData.headerElements" [class]="tableWidth(headerElement)">
        <div class="header-table-item">
          <div class="descriptor">{{headerElement.name | translate}}</div>
          <div *ngIf="!(headerElement.selectMenu || headerElement.image || headerElement.message || headerElement.recipients || headerElement.isLink)"
               class="header-table-value">{{headerElement.data}}
          </div>
          <div *ngIf="headerElement.image" class="text-margin">
            <img [src]="headerElement.data" alt="" class="image-spacer obs-dot">
          </div>
          <div *ngIf="headerElement.selectMenu" class="grid-box-text header-dropdown">
            <app-account-can-view-except  [canViewPermission]="'supervisor'" [canViewExcept]="['viewer']">
              <select2 (valueChanged)="dropDownChanged($event, headerData.oid)" [class]="headerElement.class"
                       [data]="headerElement.dropDownOptions.dropDownOptions[0]?.children" [options]="headerElement.options"
                       [value]="headerElement.value" appSelect2Parent>
              </select2>
              <div canViewExceptElse class="header-table-value">{{headerElement.data}}</div>
            </app-account-can-view-except>
          </div>
          <app-account-can-view-except *ngIf=headerElement.message [canViewExcept]="['viewer']">
            <button (click)="messageUser(headerElement)" class="grid-box-underline button-link"
                    title="{{headerElement.fullname}}">{{headerElement.data}}</button>
            <div canViewExceptElse class="header-table-value"
                 title="{{headerElement.fullname}}">{{headerElement.data}}</div>
          </app-account-can-view-except>

          <app-account-can-view-except *ngIf=headerElement.isLink [canViewPermission]="'supervisor'" [canViewExcept]="['viewer']">
            <button (click)="openLink(headerElement)"
                    class="grid-box-underline button-link">{{headerElement.data}}</button>
          </app-account-can-view-except>

          <app-account-can-view-except *ngIf="headerElement.recipients" [canViewPermission]="'supervisor'" [canViewExcept]="['viewer']">
            <div class="header-table-value">
              <button (click)="messageUser(receps)" *ngFor="let receps of headerElement.data; last as isLast"
                      class="grid-box-underLine button-link" title="{{receps.fullname}}">
                {{receps.data | delimiter:',':isLast}}
              </button>
            </div>

            <div canViewExceptElse class="header-table-value">
              <span *ngFor="let receps of headerElement.data; last as isLast"
                    title="{{receps.fullname}}">{{receps.data | delimiter:',':isLast}} <br></span>
            </div>
          </app-account-can-view-except>
        </div>
      </td>
    </tr>
  </table>
</div>
