import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { ToggleComponent } from '@shared/components/toggle/toggle.component';
import { ModalTableComponent } from '@shared/components/modal-table/modal-table.component';
import { DynamicComponentCreatorService } from '@services/dynamicComponentCreator/dynamic-component-creator.service';
import { DataTableNavigationService } from '@services/data-table-navigation/data-table-navigation.service';
import { LoadingService } from '@services/loading/loading.service';
import { ExportExcelService } from '@services/export-excel/export-excel.service';

import { TranslateService } from '@ngx-translate/core';
import * as Mark from 'mark.js';
import {
  difference,
  each,
  extend,
  filter,
  find,
  findIndex,
  flatten,
  reject,
  get,
  has,
  includes,
  isNumber,
  isString,
  keys,
  last,
  map,
  split,
  trim,
  some
} from 'lodash';

export interface DataTableOptions extends DataTables.Settings {
  enableSelectAll?: boolean;
  disableAutoResizeHandler?: boolean;
  skipCustomSortProvider?: boolean;
  columns?: DataTableColumn[];
  buttons?: DataTableButtons;
  rowvaluefrom?: string;
  select?: {
    style?: string;
    selector?: string;
  };

  onRowSelected?(currentRow: { [key: string]: any }, selectedIds: (string | number)[], event: Event): void;
  onRowClicked?(currentRow: { [key: string]: any }, event: Event): void;
}

export interface DataTableColumn extends DataTables.ColumnSettings {
  isValid?: () => boolean; // works while first init
}

type DataTableButtons = { buttons: DataTableButton[] } | DataTableButton[];

interface DataTableButton {
  extend?: string;
  columns?: string;
  text?: string;
  className?: string;
  collectionLayout?: string;
}

export interface TableColumn {
  id: string | number;
  title: string;
  headerClass: string;
  class?: string;
  width?: string;
  minwidth?: string;
  type?: string;
  fromID?: string;
  buttonClass?: string;
  buttonText?: string | ((value, row) => string);
  value?: any;
  cellval?: any;
  cellprop?: string;
  subgroupHeader?: string;
  showPopoverConfig?: {
    columns?: { id: string; title: string }[];
    getItem(id): any;
  };

  func?(value: any, row?: any): any;

  permission?(): boolean;

  sorter?(data: any): any;

  onClick?(row: any): void;

  onChange?(row: any, value: any): void;

  getValue?(): any;

  disabledFunc?(row: any, value: any): boolean;
}

export interface TableAttributes {
  columns?: TableColumn[];
  title?: string;
  disableCheckboxSelectAll?: boolean;
  colgroups?: any;
  class?: string;
  rowClass?: string;
  rowprop?: any;
  rowvaluefrom?: any;
  groupBy?: string | number;

  onCheckedAll?(isChecked: boolean, ids: number[]): void;

  getTable?(): DataTables.Api | any;

  lookupFunc?(id: number): any;

  onChecked?(tableId: string, id: any, isSelected: any, selectedIds: any[]): void;

  test?(raw: any): boolean;

  onClickRow?(id: any, row: any, event: any): void;

  rowClassFunc?(row: any, data: any): string;
}

export interface DataTableExportColumn extends DataTables.ColumnSettings {
  exportTitle?: string;
  render?: (value: any, type: string, rowData: any) => any;
}

@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor(
    private logger: NGXLogger,
    private dynamicComponentCreatorService: DynamicComponentCreatorService,
    private translate: TranslateService,
    private popoverController: PopoverController,
    private loadingService: LoadingService,
    private dataTableNavigationService: DataTableNavigationService,
    private exportExcelService: ExportExcelService
  ) {
    this.addCustomTypeOrder();
  }

  public init() {
    $(document).on('init.dt', (event, settings) => {
      const buttonInstances = get(settings, '_buttons[0].inst.s.buttons');
      const excelButtonInstance = find(buttonInstances, (buttonInstance) =>
        buttonInstance.conf.extension === '.xlsx' && buttonInstance.conf.title === '*'
      );

      if (excelButtonInstance) {
        excelButtonInstance.conf.title = null;
      }
    });
  }

  showTable(where, attrs: TableAttributes, data, datatablesConfig?: any) {
    let lastPosition = 0;

    if (datatablesConfig && $(`${where} thead`).length) {
      lastPosition = this.position(where);
      $(where).DataTable().destroy();
    }

    const $table: any = $(where);
    const tableId: string = last(split(where, '#'));
    if ($table.length) {
      // we found the element;  populate it
      $table.html('');

      const sortCols = {};

      let numCols = attrs.columns.length;
      if (attrs.hasOwnProperty('checkbox')) {
        numCols += 1;
      }
      // create the heading row
      let r: any = $('<thead>');
      if (attrs.title) {
        r.append($(`<tr><th colspan='${numCols}'>${attrs.title}</th></tr>`));
      }
      // do we need to create column groups?
      let headerHeight = 1;
      if (keys(get(attrs, 'colgroups', {})).length) {
        headerHeight = 2;
      }

      const headerRow = $('<tr>');
      if (attrs.hasOwnProperty('checkbox')) {
        let input: any;

        if (attrs.disableCheckboxSelectAll) {
          input = `<th class="no-vis" rowspan='${headerHeight}'></th>`;
        } else {
          const selectAllCheckboxId = `${tableId}tableSelectAll`;
          input = $(`<th rowspan='${headerHeight}'><div class="table-select-all-wrap"><input type="checkbox" value="1" class="table-select-all" id="${selectAllCheckboxId}"><label></label></div></th>`);
          input.on('click', `#${selectAllCheckboxId}`, (event) => {
            if (attrs.onCheckedAll) {
              if (attrs.getTable) {
                const table = attrs.getTable();
                event.currentTarget.checked ? table.rows().select() : table.rows().deselect();
              }

              let selectedIds: any = [];
              if ($(`#${tableId} .selected`).length) {
                selectedIds = map($(`#${tableId} .selected`), (selectedElement: any) => selectedElement.getAttribute('data-mid'));
              }

              attrs.onCheckedAll(event.currentTarget.checked, selectedIds);
            }
          });
        }

        headerRow.append(input);
      }
      // placeholder for items if there are colgroups
      const secondHeaderRow = $('<tr>');
      // groupHeader will point to an element that gets a colspan
      let groupHeader = null;
      let groupName = '';
      let groupColumnCount = 0;
      let hasGroups = false;
      each(attrs.columns, (name, column: number) => {
        if (name.hasOwnProperty('permission')) {
          if (!name.permission()) {
            return;
          }
        }
        const t = $('<th>');
        if (name.hasOwnProperty('sorter')) {
          sortCols[column] = {sorter: name.sorter};
        }
        if (name.hasOwnProperty('class') && (name.hasOwnProperty('type') && (name.type !== 'button' || name.hasOwnProperty('topbutton')))) {
          t.addClass(name.class);
        } else if (name.hasOwnProperty('headerClass')) {
          t.addClass(name.headerClass);
        }
        if (name.hasOwnProperty('width')) {
          t.attr('style', 'width:' + name.width + ';');
        }
        if (name.hasOwnProperty('minwidth')) {
          t.attr('style', 'min-width:' + name.minwidth + ';');
        }
        if ('string' === typeof (name)) {
          t.html(name);
        } else if (get(name, 'type') === 'button' && get(name, 'topbutton')) {
          // the field is not FROM content
          t.html(name.title);
        } else {
          if (name.title != '') {
            t.html(this.translate.instant(name.title));
          }
        }
        if (!name.hasOwnProperty('nosort')) {
          t.append($(`<span class='sort-icon'></span>`));
        }
        // are we in a group
        const gname = get(name, 'colgroup');

        if (gname) {
          // this column is in a colgroup
          hasGroups = true;
          if (gname !== groupName || column === attrs.columns.length - 1) {
            // we have changed column groups or this is the LAST column
            if (groupHeader) {
              if ((gname === groupName) && (column === attrs.columns.length - 1)) {
                groupColumnCount++;
              }
              // we have a header we are filling; close it out
              $(groupHeader).attr('colspan', groupColumnCount);
              // add the header to the row
              headerRow.append(groupHeader);
            }
            // okay - create a new group header
            const l = get(attrs.colgroups, gname);
            if (l) {
              groupHeader = $('<th>' + l.title + '</th>');
              if (name.headerClass) {
                groupHeader.addClass(name.headerClass);
              }
              groupName = gname;
              groupColumnCount = 1;
              secondHeaderRow.append(t);
            } else {
              headerRow.append(t);
            }
          } else {
            // this is in the same group
            secondHeaderRow.append(t);
            groupColumnCount++;
          }
        } else {
          // this column should not be in a group
          t.attr('rowspan', headerHeight);
          if (groupHeader) {
            // we were in a group.  close it
            $(groupHeader).attr('colspan', groupColumnCount);
            groupColumnCount = 0;
            headerRow.append(groupHeader);
            groupHeader = null;
          }
          headerRow.append(t);
        }
      });
      r.append(headerRow);
      if (hasGroups) {
        r.append(secondHeaderRow);
      }
      $table.append(r);

      // in case there is any row grouping, set that up
      let groupRowClass = 'tr-odd';
      let groupCell = null;
      let groupValue = '';
      let groupValueID = '';
      let groupCount = 0;

      const $body = $table.append('<tbody>');

      // loop over the data
      each(data, (rowItem: any, index) => {
        // capture information about the row so events know what
        // (really) first - is there a method we need to call to dereference the item
        let row = rowItem;
        if (attrs.lookupFunc) {
          row = attrs.lookupFunc(rowItem);
          if (!row) {
            return true;
          }
        }
        // first - is there a test attribute?  It is a callback
        if (attrs.hasOwnProperty('test') && !attrs.test(row)) {
          // the test function returned false; bail out
          return true;
        }
        // data to reference

        let rowID: any = null;
        if (row.fieldName && row.fieldName === 'responseID') {
          if (row.items && row.items.length && row.items[0].responseID) {
            rowID = row.items[0].responseID;
          }
        }
        if (rowID) {
          r = $(`<tr data-mid=${rowID} data-row='${index}'>`);
        } else {
          r = $(`<tr data-row='${index}'>`);
        }

        r.click((event) => {
          const id = event.currentTarget.getAttribute('data-mid');

          if ($(event.target).hasClass('select-checkbox')) {
            if (attrs.onChecked) {
              setTimeout(() => {
                let selectedIds: any = [];
                if ($(`#${tableId} .selected`).length) {
                  selectedIds = map($(`#${tableId} .selected`), (selectedElement: any) => selectedElement.getAttribute('data-mid'));
                }
                attrs.onChecked(tableId, id, $(event.currentTarget).hasClass('selected'), selectedIds);
                const selectedCount: number = $(`${where} .selected`).length;
                const allCount: number = $(`${where} tr[data-row]`).length;
                const selectAllCheckboxElement: any = $(`#${tableId}tableSelectAll`);

                if (selectedCount === allCount) {
                  selectAllCheckboxElement.prop('checked', true);
                }

                if (selectedCount === 0) {
                  selectAllCheckboxElement.prop('checked', false);
                }
              });
            }
          } else {
            if (attrs.onClickRow) {
              attrs.onClickRow(id, row, event);
            }
          }
        });
        if (attrs.class) {
          r.attr('class', attrs.class);
        } else if (attrs.rowClass) {
          r.attr('class', attrs.rowClass);
        }
        if (attrs.rowClassFunc) {
          const c = attrs.rowClassFunc(row, data);
          if (c && c !== '') {
            r.addClass(c);
          }
        }
        if (!attrs.hasOwnProperty('groupBy')) {
          groupRowClass = groupRowClass === 'tr-odd' ? 'tr-even' : 'tr-odd';
          r.addClass(groupRowClass);
        }
        if (attrs.rowprop) {
          if (Array.isArray(attrs.rowprop) && (attrs.rowprop.length === attrs.rowvaluefrom.length)) {
            for (let i = 0; i < attrs.rowprop.length; i++) {
              r.attr(attrs.rowprop[i], row[attrs.rowvaluefrom[i]]);

            }
          } else {
            r.attr(attrs.rowprop, row[attrs.rowvaluefrom]);
          }
        }
        if (attrs.hasOwnProperty('checkbox')) {
          r.append('<td></td>');
        }
        each(attrs.columns, (name, column: any) => {

          if (name.hasOwnProperty('permission')) {
            if (!name.permission()) {
              return;
            }
          }

          let baseVal = null;
          let baseID = null;
          let c = null;
          const cell = $('<td>');
          if ('string' === typeof (name)) {
            baseVal = c = row[name];
            baseID = row.itemID;
          } else {
            // this is an object about a column
            if (name.showPopoverConfig) {
              let from = name.id;
              if (name.hasOwnProperty('fromID')) {
                from = name.fromID;
              }

              if (row[from].length > 1) {
                const linkElement = $(`<span class="table-modal-link">${row[from].length} ${name.title}</span>`);

                linkElement.on('click', (event: Event) => {
                  event.stopPropagation();
                  const columns = [{
                    id: 'name',
                    title: this.translate.instant('MGMT_DETAILS.Title')
                  }];

                  const config = {
                    title: name.title,
                    data: map(row[from], (id: number) => name.showPopoverConfig.getItem(id)),
                    columns: name.showPopoverConfig.columns || columns
                  };

                  this.popoverController.create({
                    component: ModalTableComponent,
                    animated: false,
                    componentProps: {config, tableService: this}
                  }).then((element: HTMLIonPopoverElement) => {
                    element.present();
                  });
                });
                c = linkElement;
              } else {
                c = name.func(row[from][0], row);
              }
            } else if (name.hasOwnProperty('func') && typeof name.func === 'function') {
              let from = name.id;
              if (name.hasOwnProperty('fromID')) {
                from = name.fromID;
              }
              baseVal = row[from];
              baseID = row.itemID;
              c = name.func(row[from], row);
            } else if (name.hasOwnProperty('type') && name.type === 'button') {
              // the field is not FROM content

              // const b = $(`<button class="page-button secondary" color="secondary">`);
              const b = $(`<span class='button-styled'>`);

              if (name.onClick) {
                b.on('click', (event: Event) => {
                  event.stopPropagation();
                  name.onClick(row[name.fromID]);
                });
              }

              if (name.hasOwnProperty('buttonText')) {
                if (isString(name.buttonText)) {
                  b.html(this.translate.instant((<any>name).buttonText));
                } else {
                  const res: string = (<any>name).buttonText(row[name.fromID], row);
                  b.html(this.translate.instant(res));
                }
              } else {
                b.html('Edit');
              }
              if (name.hasOwnProperty('buttonClass')) {
                b.addClass(name.buttonClass);
              } else {
                b.addClass('page-button');
              }
              b.attr('data-value', row[name.fromID]).appendTo(cell);
              baseVal = row[name.fromID];
              baseID = row.itemID;
            } else if (name.hasOwnProperty('type') && name.type === 'flipswitch') {

              const theID = name.hasOwnProperty('fromID') ? name.fromID : name.id;

              c = this.dynamicComponentCreatorService.create(ToggleComponent, (componentInstance: ToggleComponent) => {
                componentInstance.fieldName = <string>theID;
                componentInstance.isChecked = row[theID] === 1;
                if (name.hasOwnProperty('value')) {
                  componentInstance.enabledValue = name.value;
                }
                if (name.hasOwnProperty('disabledFunc')) {
                  if (name.disabledFunc(row, row[theID])) {
                    componentInstance.isDisabled = true;
                  }
                }
                if (name.hasOwnProperty('onChange')) {
                  componentInstance.onChange.subscribe((value) => {
                    if (row[theID] !== value) {
                      name.onChange(data[index], value);
                    }
                  });
                }
                name.getValue = () => componentInstance.getValue();
              });
            } else if (name.hasOwnProperty('fromID')) {
              cell.attr('data-value', row[name.fromID]);
              baseVal = c = row[name.fromID];
              baseID = row.itemID;
            } else {
              baseVal = c = row[name.id];
              baseID = row.itemID;
            }
            if (name.hasOwnProperty('class')) {
              cell.addClass(name.class);
            }
            if (name.hasOwnProperty('cellprop')) {
              if (name.hasOwnProperty('cellval')) {
                let attrVal;
                if (typeof (name.cellval) === 'function') {
                  attrVal = name.cellval(c, row);
                } else {
                  attrVal = row[name.cellval] ? row[name.cellval] : 0;
                }
                cell.attr(name.cellprop, attrVal);
              } else {
                cell.attr(name.cellprop, c);
              }
            }
          }
          if (c !== null) {
            cell.append(c);
          }
          if (attrs.hasOwnProperty('groupBy')) {
            if (column === attrs.groupBy) {
              if (baseVal === groupValue && row.secondFieldName) {
                r.addClass(groupRowClass);
                groupCount++;
                groupCell.attr('rowspan', groupCount);
                // we don't add this new cell - it is part of the group
              } else {
                groupRowClass = groupRowClass === 'tr-odd' ? 'tr-even' : 'tr-odd';
                groupCount = 1;
                groupCell = cell;
                groupCell.addClass('cell-group');
                groupValue = baseVal;
                groupValueID = baseID;
                r.addClass(groupRowClass);
                cell.appendTo(r);
              }
            } else {
              cell.appendTo(r);
            }
          } else {
            // is it possible that this column should span the rest of the columns?
            if (name.hasOwnProperty('subgroupHeader') && c === name.subgroupHeader) {
              const cols = attrs.columns.length - column;
              cell.attr('colspan', cols);
              cell.appendTo(r);
              // exit the loop
              return false;
            } else {
              cell.appendTo(r);
            }
          }
        });
        r.appendTo($body);
      });
      const sortOpts: any = {widgets: ['saveSort'], sortList: [[0, 0]]};
      if (Object.keys(sortCols).length) {
        sortOpts.headers = sortCols;
      }
      try {
        $table.table('refresh');
      } catch (e) {
        // this.logger.log('Error: ' + e.toString());
      }
    } else {
      this.logger.log('selector ' + where + ' not in document!');
    }

    if (datatablesConfig) {
      if (!datatablesConfig.scrollY) {
        const offset: number = get($table.closest('.table-container').offset(), 'top') || 0;
        datatablesConfig.scrollY = $(window).height() - offset - 150;
      }

      const tableTableElement: any = $table.DataTable(datatablesConfig);
      $table.width('100%');

      tableTableElement.columns.adjust().draw(false);
      if (!datatablesConfig.disableAutoResizeHandler) {
        this.handleResize();
      }
      this.position(where, lastPosition);

      return tableTableElement;
    }

  }

  resetTable(theTable) {
    try {
      $(theTable).DataTable().clear().draw();
      $(theTable).DataTable().destroy();
    } catch (err) {
      this.logger.log(err);
    }
  }

  public handleResize(target: string = '.dataTables_scrollBody:visible:last', bottomPadding: number = 50, timeout: number = 200, drawPaging = true): boolean {
    const resize: () => void = () => {
      const e = $(`${target}`);
      if (e && e[0]) {
        const c = e.offset();
        if (c) {
          // we have an offset for a table
          const h = $(window).height() - c.top - bottomPadding;
          if (h && h > 30) {
            e.css({height: h + 'px'});
            e.css({'max-height': h + 'px'});
          }
        }
      }
      each($.fn.dataTable.tables(true), (table) => {
        const lastPosition = this.position(table);
        const dataTable = $(table).DataTable();
        dataTable.draw(drawPaging).columns.adjust();

        if (drawPaging) {
          this.position(table, lastPosition);
        }
      });
    };

    if (isNumber(timeout)) {
      window.setTimeout(() => resize(), timeout);
    } else {
      resize();
    }

    return true;
  }

  public addCustomTypeOrder() {
    extend((<any>$.fn).dataTableExt.oSort, {
      'num-html-pre': (a) => {
        const x = String(a).replace(/<[\s\S]*?>/g, '');
        return parseFloat(x);
      },

      'num-html-asc': (a, b) => ((a < b) ? -1 : ((a > b) ? 1 : 0)),

      'num-html-desc': (a, b) => ((a < b) ? 1 : ((a > b) ? -1 : 0))
    });
  }

  public position(selector: string, position?: number): number {
    const scrollBody = $(selector).DataTable().settings()[0].nScrollBody;

    if (isNumber(position)) {
      $(scrollBody).scrollTop(position);
    } else {
      return $(scrollBody).scrollTop();
    }
  }

  public quoteEncoding(strvalue) {
    const strquotes = /(^["']|["']$)/g;
    return strvalue.replace(strquotes, '');
  }

  /** This method works only for datatables instance with virtualScroll enabled option */
  public addCheckboxHandler(tableInstance, tableAttr, onSelected: (ids) => void) {
    $(tableInstance.header()).off('click').on('click', '.select-all', (event: Event) => {
      const checkboxElement = $(event.currentTarget);

      if (checkboxElement.hasClass('selected')) {
        tableInstance.rows().deselect();
        checkboxElement.removeClass('selected');
      } else {
        tableInstance.rows({search: 'applied'}).select();
        checkboxElement.addClass('selected');
      }
      const ids = this.getSelectedRowIds(tableInstance, tableAttr);
      onSelected(ids);
    });
  }

  public getSelectedRowIds(tableInstance, tableAttr): string[] {
    const selectedRows = tableInstance.rows({selected: true}).data();
    return map(selectedRows, tableAttr.rowvaluefrom) as string[];
  }

  public showDataTable(selector: string | HTMLElement, opts: DataTableOptions, destroy: boolean = false): DataTables.Api {
    this.defineDefaults();
    const targetTableElement = $(`${selector}`);

    if (targetTableElement.find('thead').length) {
      if (destroy || this.isDifferentColumnsConfig(selector, opts)) {
        targetTableElement.DataTable().destroy();
        targetTableElement.empty();
      } else {
        const tableInstance = targetTableElement.DataTable();

        tableInstance.clear().rows.add(opts.data);
        tableInstance.rows().invalidate().draw();
        if (opts.enableSelectAll) {
          $(tableInstance.table(null).header()).find('.select-all').removeClass('selected');
        }
        if (!opts.disableAutoResizeHandler) {
          this.handleResize('.dataTables_scrollBody:visible:last', 20, 200, false);
        }
        opts.onRowSelected && opts.onRowSelected(null, [], null);

        setTimeout(() => {
          tableInstance.columns.adjust();
        });

        return tableInstance;
      }
    }

    if (!opts.skipCustomSortProvider) {
      this.dataTableNavigationService.registerCustomSortProvider(opts);
    }

    if (opts.scrollY && !has(opts, 'scrollCollapse')) {
      opts.scrollCollapse = true;
    }

    this.validateColumns(opts);
    this.applySortingIcons(opts);

    const tableInstance: DataTables.Api | any = targetTableElement.DataTable(opts);
    $(tableInstance.table().header()).addClass('table-header sorting');
    this.loadingService.disable();

    setTimeout(() => {
      tableInstance.columns.adjust().draw(true);
      if (!opts.disableAutoResizeHandler) {
        this.handleResize('.dataTables_scrollBody:visible:last', 20, 200, false);
      }
    });

    if (opts.enableSelectAll) {
      const onRowSelected = (ids) => {
        opts.onRowSelected && opts.onRowSelected(null, ids, null);
      };

      this.addCheckboxHandler(tableInstance, opts, onRowSelected);
    }

    tableInstance.off('select deselect').on('select deselect', (event, dt, type, indexes) => {
      if (type === 'row') {
        const currentRow = tableInstance.rows(indexes).data()[0];
        const selectedRowsIds = this.getSelectedRowIds(tableInstance, opts);
        opts.onRowSelected && opts.onRowSelected(currentRow, selectedRowsIds, event);
      }
    });

    targetTableElement.find('tbody').off('click').on('click', 'tr', (event: Event) => {
      const currentRow = tableInstance.row(event.currentTarget).data();
      const targetClass = trim((<any>event).target.className);

      if (!includes(targetClass, 'select-checkbox')) {
        opts.onRowClicked && opts.onRowClicked(currentRow, event);
      }
    });

    return tableInstance;
  }

  public validateColumns(opts: DataTableOptions) {
    const columns = opts?.columns || [];
    const invalidColumns: string[] = [];

    opts.columns = filter(columns, (column) => {
      const isValid = column?.isValid ? column.isValid() : true;

      if (!isValid) {
        const columnName = column.name || column.data as string;
        invalidColumns.push(columnName);
      }

      return isValid;
    });

    if (opts?.columnDefs) {
      const visibleColumnDef = find(opts.columnDefs, { visible: true });
      const invisibleColumnDef = find(opts.columnDefs, { visible: false });

      this.syncUpColumnIndexes(visibleColumnDef, columns, invalidColumns);
      this.syncUpColumnIndexes(invisibleColumnDef, columns, invalidColumns);
    }
  }

  private syncUpColumnIndexes(columnDef: DataTables.ColumnDefsSettings, columns: DataTableColumn[], invalidColumns: string[]) {
    const columnNames: string[] = [];
    const filteredColumns = reject(columns, (column) => {
      return includes(invalidColumns, column.name) || includes(invalidColumns, column.data);
    });

    if (columnDef?.targets) {
      each(flatten([columnDef.targets]), (index) => {
        const targetColumn = columns[index];
        const columnName = targetColumn?.name || targetColumn?.data;

        if (targetColumn && !includes(invalidColumns, columnName)) {
          columnNames.push(targetColumn?.name || targetColumn?.data);
        }
      });

      columnDef.targets = [];
      each(columnNames, (columnName) => {
        const columnIndex = findIndex(filteredColumns, (column) => {
          return column.name === columnName || column.data === columnName;
        });

        if (columnIndex >= 0) {
          (columnDef.targets as number[]).push(columnIndex);
        }
      });
    }
  }

  public highlightSearchResult(selector: string, value: string) {
    const searchString = value;
    const instance = new Mark(document.querySelector(`${selector}.dataTable tbody`));

    if (instance) {
      instance.unmark();

      if (searchString) {
        instance.mark(searchString, {element: 'span', className: 'highlight'});
      }
    }
  }

  public async exportExcel(tableSelector: HTMLTableElement | string, title?: string) {
    const datatableInstance = $(tableSelector as string).DataTable();

    if (title) {
      title = this.translate.instant(title);
    }

    if (datatableInstance) {
      await this.loadingService.enable(this.translate.instant('SHARED.View_export_excel'));
      const columns = filter(datatableInstance.settings().init().columns, (column: DataTableExportColumn) => {
        return column.exportTitle || column.title;
      }) as DataTableExportColumn[];
      const columnLabels = filter(map(columns, (column) => {
        if (column.exportTitle) {
          return this.translate.instant(column.exportTitle);
        } else if (column.title) {
          return trim($(`<div>${column.title}</div>`).text());
        }
      }));
      const filteredRowsData = datatableInstance.rows({ search: 'applied' }).data().toArray();
      const data = map(filteredRowsData, (rowData) => {
        return map(columns, (column) => {
          if (column.render) {
            const rowStr = column.render(rowData[<string>column.data], 'export', rowData);
            return isString(rowStr) ? filter(rowStr.split('<br />')).join(', ') : rowStr;
          } else {
            return rowData[column.data as string];
          }
        });
      });

      const sheetData = {
        Sheet: [
          columnLabels,
          ...data
        ]
      };

      this.exportExcelService.export(sheetData, title);
      this.loadingService.disable();
    }
  }

  private isDifferentColumnsConfig(selector: string | HTMLElement, opts: DataTableOptions) {
    const targetTableElement = $(`${selector}`);
    const currentColumns = map(targetTableElement.DataTable().settings().init().columns, 'title') as string[];
    const newColumns = map(opts.columns, 'title') as string[];

    return difference(currentColumns, newColumns).length > 0 || currentColumns.length !== newColumns.length;
  }

  public defineDefaults() {
    extend($.fn.dataTable.defaults, {
      language: {
        searchPlaceholder: this.translate.instant('SHARED.Search'),
        emptyTable: this.translate.instant('SHARED.emptyTable'),
        sZeroRecords: this.translate.instant('SHARED.sZeroRecords'),
        info: this.translate.instant('OTable.Info'),
        infoEmpty: this.translate.instant('OTable.Info_Empty')
      }
    });
  }

  private applySortingIcons(opts: DataTableOptions) {
    const isSortIconAdded = some(opts.columns, (column) => {
      return includes(column?.title, 'sort-icon');
    });

    if (opts.ordering !== false && !isSortIconAdded) {
      each(opts.columns, (column, index) => {
        const isSortingDisabled = some(filter(opts?.columnDefs, { orderable: false }), (columnDef) => {
          return includes(flatten([columnDef.targets]), index);
        });

        if (column?.title && !isSortingDisabled) {
          column.title = `${column.title} <span class="sort-icon"></span>`;
        }
      });
    }
  }
}
